"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const i18nConstants_1 = require("../constants/i18nConstants");
const StorageKeysConstants_1 = require("../constants/StorageKeysConstants");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const BasketSelector_1 = require("../redux/basket/selectors/BasketSelector");
const BasketPopoverContainer_1 = require("./BasketPopoverContainer");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const SessionStorageUtil_1 = require("../util/SessionStorageUtil");
const useFeatureToggle_1 = require("../hooks/useFeatureToggle");
const EventUtil_1 = require("../util/EventUtil");
const EventEnum_1 = require("../enums/EventEnum");
const common_utils_1 = require("@as-react/common-utils");
const AdobeTargetConstants_1 = require("../constants/AdobeTargetConstants");
const BasketReminderNotification_1 = require("../components/basket/basket-reminder-notification/BasketReminderNotification");
const LockedBasketNotificationMessage_1 = require("../components/notification-message/LockedBasketNotificationMessage");
const useABTest_1 = require("../hooks/useABTest");
const YonderlandMediator_1 = require("../mediator/YonderlandMediator");
const YonderlandMediatorTypes_1 = require("../mediator/types/YonderlandMediatorTypes");
const URLParamUtil_1 = require("../util/URLParamUtil");
const BasketConstants_1 = require("../constants/BasketConstants");
const BasketMenuItemContainer = ({ basketOverviewLink, freeDeliveryMessage, enableBasketReminderNotification, enableBasketReminderNotificationABTest, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.navigation);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const { isConfirmationPage, isPDPPage, isHomePage, isBrandPage, isBrandAsCategoryPage, isListerPage, isCategoryPage, isBasketOverviewPage, } = (0, ConfigurationUtil_1.getPageInfo)(aemConfiguration);
    const isCommercialPage = isHomePage || isPDPPage || isBrandPage || isBrandAsCategoryPage || isListerPage || isCategoryPage;
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)({ isSsr: true });
    const dispatch = (0, react_redux_1.useDispatch)();
    const productAmount = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalQuantityState)());
    const activeBasketReminder = (0, useFeatureToggle_1.useFeatureToggle)('active_basket_reminder');
    const noBasketPopup = (0, useFeatureToggle_1.useFeatureToggle)('no_basket_popup_on_click');
    const buttonEl = (0, react_1.useRef)(null);
    const [isBasketChanged, setIsBasketChanged] = (0, react_1.useState)(false);
    const promotionProducts = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPromotionProductsState)());
    const [firstVisit, setFirstVisit] = (0, react_1.useState)(false);
    const showBasketReminderABTest = Boolean((0, useABTest_1.useABTest)(common_types_1.ABTestCookie.BASKET_REMINDER_NOTIFICATION));
    const isClient = (0, cotopaxi_1.useIsClient)();
    const addVoucherParam = isClient ? (0, URLParamUtil_1.getQuerystringByName)(window.location.search, BasketConstants_1.ADD_VOUCHER_PARAM_KEY) : false;
    (0, react_1.useEffect)(() => {
        EventUtil_1.default.listen(EventEnum_1.EventEnum.BASKET_CHANGED, () => {
            setIsBasketChanged(true);
            SessionStorageUtil_1.default.setItem(StorageKeysConstants_1.IS_NEW_SESSION, false);
        });
    }, []);
    (0, react_1.useEffect)(() => {
        var _a;
        if (activeBasketReminder &&
            isCommercialPage &&
            productAmount > 0 &&
            isDesktop &&
            !isBasketChanged &&
            SessionStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.IS_NEW_SESSION) &&
            common_utils_1.CookieUtil.get(common_types_1.Cookie.IS_BASKET_REMINDER_CALLED) !== 'true') {
            if (!showBasketReminderNotification()) {
                (_a = buttonEl.current) === null || _a === void 0 ? void 0 : _a.click();
            }
            common_utils_1.CookieUtil.set(common_types_1.Cookie.IS_BASKET_REMINDER_CALLED, 'true');
        }
    }, [productAmount]);
    (0, react_1.useEffect)(() => {
        var _a;
        const hasPromotionProduct = promotionProducts.length > 0;
        if (!hasPromotionProduct) {
            return;
        }
        const promotion = (_a = promotionProducts.find(product => !!product.promotion)) === null || _a === void 0 ? void 0 : _a.promotion;
        const minimumBasketSize = promotion === null || promotion === void 0 ? void 0 : promotion.minimumBasketSize;
        !addVoucherParam &&
            YonderlandMediator_1.default.publish({
                event: AdobeTargetConstants_1.ADOBE_TARGET_PRESOLDEN,
                group: YonderlandMediatorTypes_1.YonderlandMediatorGroup.BASKET,
                data: {
                    origin: 'BasketMenuItemContainer',
                    hasPreSoldenProduct: hasPromotionProduct,
                    isPreSoldenFulfilled: Boolean(hasPromotionProduct && minimumBasketSize && productAmount >= minimumBasketSize),
                    hasMultipleProducts: productAmount > 1,
                },
            });
    }, [promotionProducts]);
    (0, react_1.useEffect)(() => {
        if (!isConfirmationPage) {
            dispatch(BasketActions_creators_1.basketActions.createGetAction());
        }
        if (SessionStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.IS_NEW_SESSION) === null) {
            SessionStorageUtil_1.default.setItem(StorageKeysConstants_1.IS_NEW_SESSION, true);
        }
        if (SessionStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.HAS_ACTIVE_SESSION) === null) {
            setFirstVisit(true);
            SessionStorageUtil_1.default.setItem(StorageKeysConstants_1.HAS_ACTIVE_SESSION, true);
        }
    }, []);
    const withHandler = isDesktop && !noBasketPopup;
    const handleClick = (event) => {
        dispatch(BasketActions_creators_1.basketActions.createGetAction());
        event && handlePopoverOpen(event);
    };
    const basketPopoverTitle = () => productAmount
        ? t(productAmount > 1 ? 'basket.popover.title_plural' : 'basket.popover.title', {
            count: productAmount,
        })
        : t('basket.popover.title.default');
    const isFirstVisitAndBasketFilled = () => !isBasketOverviewPage && firstVisit && productAmount > 0 && !isBasketChanged;
    const showBasketReminderNotification = () => !enableBasketReminderNotification || (enableBasketReminderNotificationABTest && !showBasketReminderABTest)
        ? false
        : isFirstVisitAndBasketFilled();
    return ((0, jsx_runtime_1.jsxs)("div", { "data-analytics-bannerid": "header-shopping-cart", children: [(0, jsx_runtime_1.jsx)(LockedBasketNotificationMessage_1.LockedBasketNotificationMessage, {}), (0, jsx_runtime_1.jsx)(cotopaxi_1.ConditionalWrapper, { condition: showBasketReminderNotification(), wrapper: children => ((0, jsx_runtime_1.jsx)(BasketReminderNotification_1.BasketReminderNotification, { hrefViewCart: basketOverviewLink, children: children })), children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { badge: productAmount, badgeAnimated: isFirstVisitAndBasketFilled(), variant: cotopaxi_1.ButtonVariant.NAVIGATION, href: withHandler ? undefined : basketOverviewLink, onClick: withHandler ? handleClick : undefined, dataQA: "basket", dataBadgeQA: "basket_counter", icon: {
                        name: cotopaxi_1.IconName.CART,
                        size: cotopaxi_1.IconSize.MAJOR,
                    }, text: "Shopping cart", innerRef: buttonEl }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { noPadding: true, dataQA: "basket_popover", anchorEl: anchorEl, title: basketPopoverTitle(), onClose: handlePopoverClose, rightElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: handlePopoverClose, icon: {
                        name: cotopaxi_1.IconName.CLOSE,
                        color: cotopaxi_1.IconColor.ACTION,
                        size: cotopaxi_1.IconSize.MAJOR,
                    } }), size: cotopaxi_1.PopoverSize.EXTRA_LARGE, children: (0, jsx_runtime_1.jsx)(BasketPopoverContainer_1.BasketPopoverContainer, { freeDeliveryMessage: freeDeliveryMessage, hrefViewCart: basketOverviewLink }) })] }));
};
exports.default = BasketMenuItemContainer;
