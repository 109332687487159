"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTileOrderOverview = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const ImageUtil_1 = require("../../../util/ImageUtil");
const andes_react_1 = require("@yonderland/andes-react");
const common_utils_1 = require("@as-react/common-utils");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const asNumeral_1 = require("../../../vendors/asNumeral");
const ProductTileOrderOverview = ({ product }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.navigation);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const productImageConfiguration = (0, ConfigurationUtil_1.getProductImages)(aemConfiguration);
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const image = (0, ImageUtil_1.getImageUrlForBasketTile)(product, productImageConfiguration, common_types_1.ProductImageSizesPortraitEnum.bundles);
    const color = product.color ? (0, common_utils_1.capitalize)(product.color) : '';
    const size = product.originalSize;
    const getSizeColorLabel = () => {
        if (size !== '.' && color) {
            return t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:basket.flyout.options`, {
                size: size,
                color: color,
            });
        }
        else if (size !== '.' && !color) {
            return t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:basket.flyout.options.size`, {
                size: size,
            });
        }
        else if ((size === '.' && color) || !size) {
            return t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:basket.flyout.options.color`, {
                color: color,
            });
        }
        return '';
    };
    const priceActual = {
        formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(product.totalPrice),
    };
    const pricePrevious = product.productHasDiscount && !!product.totalPreviousPrice
        ? {
            formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(product.totalPreviousPrice),
        }
        : undefined;
    const priceViewModel = ((_a = product.prices) === null || _a === void 0 ? void 0 : _a.SELL)
        ? (0, common_utils_1.generateCotopaxiPriceViewModel)({
            prices: product.prices,
            hasDiscount: Boolean(product.productHasDiscount),
        }, t, asNumeral_1.AsNumeral.getInstance().formatAsCurrency)
        : {
            priceActual,
            pricePrevious,
        };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.ProductTileHorizontal, { gap: "3", product: Object.assign(Object.assign({ image,
            currency }, priceViewModel), { title: product.description, brand: product.brand }), dataTestId: "product_tile", children: [(0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Cell, { section: "media", children: (0, jsx_runtime_1.jsx)(andes_react_1.ProductTileImage, { height: 120, width: 80 }) }), (0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Cell, { section: "content", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "5", children: [(0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Title, { brandOnTheSameLine: true, lineClamp: 2 }), (0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { color: "subdued-2", children: [product.quantity > 1 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.count`, { count: product.quantity }), ", "] })), getSizeColorLabel()] }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { marginInlineStart: "auto", children: (0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Price, {}) })] }) })] }));
};
exports.ProductTileOrderOverview = ProductTileOrderOverview;
