"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const DpeApi_1 = require("../../../api/dpe/DpeApi");
const DateUtil_1 = require("../../../util/DateUtil");
const FormValidationUtil_1 = require("../../../util/FormValidationUtil");
const EventUtil_1 = require("../../../util/EventUtil");
const EventEnum_1 = require("../../../enums/EventEnum");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const DpeVitalityForm_1 = require("../dpe-vitality-form/DpeVitalityForm");
const cotopaxi_1 = require("@as/cotopaxi");
const DpeVitality = ({ apiConfig, onValidationError, onValidationSuccess, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.dpe);
    const [responseErrorMessage, setResponseErrorMessage] = (0, react_1.useState)(null);
    const validateForm = (values) => {
        const { customerNumber, day, month, year } = values;
        const errors = {};
        if (!customerNumber) {
            errors.customerNumber = t(common_types_1.ErrorMessage.REQUIRED);
        }
        else if (!FormValidationUtil_1.default.isOfCorrectLength(customerNumber, 10)) {
            errors.customerNumber = t('dpe.vitality.error.invalidNumber');
        }
        else if (!FormValidationUtil_1.default.containsOnlyNumbers(customerNumber)) {
            errors.customerNumber = t('dpe.vitality.error.invalidNumber');
        }
        if (!day) {
            errors.day = t(common_types_1.ErrorMessage.REQUIRED);
        }
        if (!month) {
            errors.month = t(common_types_1.ErrorMessage.REQUIRED);
        }
        if (!year) {
            errors.year = t(common_types_1.ErrorMessage.REQUIRED);
        }
        if (day && month && year) {
            const maxDays = (0, DateUtil_1.getDaysInMonth)(Number(year), Number(month));
            if (Number(day) > maxDays) {
                errors.day = t('dpe.vitality.error.invalidNumber');
            }
        }
        if (Object.keys(errors).length > 0) {
            onValidationError(errors);
        }
        return errors;
    };
    const handleVitalityCheckError = (error) => {
        var _a;
        let stateToSet = t(common_types_1.ErrorMessage.GENERAL_VITALITY);
        if (error.response && error.response.status !== 500 && error.response.data) {
            stateToSet = (_a = error.response.data[0]) === null || _a === void 0 ? void 0 : _a.message;
        }
        setResponseErrorMessage(stateToSet);
        onValidationError({
            api: stateToSet,
        });
    };
    const refreshCustomerVouchers = () => BasketAPI_1.default.refreshCustomerVouchers(apiConfig.apiPrefix, apiConfig.defaultRequestParameters)
        .then(() => {
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_CHANGED);
        onValidationSuccess();
    })
        .catch(error => handleVitalityCheckError(error));
    const checkVitality = (requestData, actions) => {
        DpeApi_1.default.checkVitality(requestData, apiConfig.apiPrefix, apiConfig.defaultRequestParameters)
            .then(refreshCustomerVouchers)
            .catch(error => {
            handleVitalityCheckError(error);
        })
            .then(() => {
            actions.setSubmitting(false);
        });
    };
    const handleSubmit = ({ customerNumber, day, month, year }, actions) => {
        setResponseErrorMessage(null);
        const birthDate = new Date(`${year}/${month}/${day}`);
        const requestData = {
            membershipNumber: customerNumber,
            dateOfBirth: (0, DateUtil_1.formatDate)((0, DateUtil_1.format)(birthDate.toString(), GeneralConstants_1.DateFormat.DD_MM_YYYY.value), GeneralConstants_1.DateFormat.DD_MM_YYYY.value),
        };
        checkVitality(requestData, actions);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [responseErrorMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: responseErrorMessage, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE }) })), (0, jsx_runtime_1.jsx)(DpeVitalityForm_1.DpeVitalityForm, { onSubmit: handleSubmit, validate: validateForm })] }));
};
exports.default = DpeVitality;
