"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeDeliveryAddressSelector = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const react_query_1 = require("@tanstack/react-query");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const HomeDeliveryAddressCard_1 = require("./HomeDeliveryAddressCard");
const andes_icons_1 = require("@yonderland/andes-icons");
const DeliverySheet_1 = require("../DeliverySheet");
const react_1 = require("react");
const HomeDeliveryAddressSelector = ({ selectedAddressId }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.general]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [{ info, openSelector }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const isOpen = openSelector === 'delivery-address';
    const { deliveryAddresses: deliveryAddressesInfo } = info;
    const [highlightedAddress, setHighlightedAddress] = (0, react_1.useState)();
    const [editingAddressId, setEditingAddressId] = (0, react_1.useState)();
    const [creatingNewAddress, setCreatingNewAddress] = (0, react_1.useState)(false);
    const showCTA = Boolean(!editingAddressId && highlightedAddress);
    const { getDeliveryAddresses } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { addDeliveryAddressMutation, updateDeliveryAddressMutation } = (0, common_queries_1.customerMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { mutate: addDeliveryAddress, error: addDeliveryAddressError, isPending: addDeliveryAddressIsPending, } = (0, react_query_1.useMutation)(addDeliveryAddressMutation());
    const { mutate: updateDeliveryAddress, error: updateDeliveryAddressError, isPending: updateDeliveryAddressIsPending, } = (0, react_query_1.useMutation)(updateDeliveryAddressMutation());
    const queryClient = (0, react_query_1.useQueryClient)();
    const { data: deliveryAddressesData } = (0, react_query_1.useQuery)(getDeliveryAddresses({
        enabled: isOpen && !(deliveryAddressesInfo === null || deliveryAddressesInfo === void 0 ? void 0 : deliveryAddressesInfo.length),
        select: data => data === null || data === void 0 ? void 0 : data.deliveryAddresses,
        initialData: () => {
            var _a;
            return ({
                customerAddress: (_a = info.customerDetails) === null || _a === void 0 ? void 0 : _a.address,
                deliveryAddresses: deliveryAddressesInfo,
            });
        },
    }));
    const addressFormat = (address) => {
        const houseNumberAdditionTranslation = address.houseNumberAddition
            ? address.idCountry === 15
                ? t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.delivery.addition`)
                : t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.delivery.bus`)
            : '';
        const bus = houseNumberAdditionTranslation && ` ${houseNumberAdditionTranslation} ${address.houseNumberAddition}`;
        const company = address.companyName ? `${address.companyName}, ` : '';
        const countyName = address.county ? ` ${address.county}` : '';
        const street = address.houseNumber ? `${address.address} ${address.houseNumber}` : address.address;
        const fullName = address.firstName && address.lastName ? `${address.firstName} ${address.lastName}, ` : '';
        const addressLine = [address.addressLine2, address.addressLine3, address.addressLine4].filter(Boolean);
        const town = address.country && ['GB', 'BFPO', 'IE', 'United Kingdom'].includes(address.country)
            ? `, ${address.city}, ${address.postCode}`
            : ` ${address.postCode} ${address.city}`;
        return `${fullName}${company}${street}${bus}${addressLine.length ? `, ${addressLine.join(', ')}` : ''}${town}${countyName}`;
    };
    const selectDeliveryAddress = () => {
        dispatch({ type: 'set_selected_delivery_address', payload: highlightedAddress });
        dispatch({ type: 'set_open_selector', payload: undefined });
    };
    const resetDeliveryOption = () => {
        dispatch({ type: 'set_open_selector', payload: undefined });
    };
    const handleCreateNewAddress = () => {
        setHighlightedAddress(undefined);
        setEditingAddressId(undefined);
        setCreatingNewAddress(true);
    };
    const handleSubmitNewAddress = (data) => {
        addDeliveryAddress(data, {
            onSuccess: data => {
                setCreatingNewAddress(false);
                setHighlightedAddress(data);
                updateDeliveryAddresses(oldData => (Object.assign(Object.assign({}, oldData), { deliveryAddresses: [...oldData.deliveryAddresses, data] })));
            },
        });
    };
    const handleSubmitUpdateAddress = (data) => {
        updateDeliveryAddress(data, {
            onSuccess: data => {
                setEditingAddressId(undefined);
                setHighlightedAddress(data);
                updateDeliveryAddresses(oldData => (Object.assign(Object.assign({}, oldData), { deliveryAddresses: oldData.deliveryAddresses.map(address => address.deliveryAddressId === data.deliveryAddressId
                        ? data
                        : address) })));
            },
        });
    };
    const updateDeliveryAddresses = (callback) => queryClient.setQueryData(common_queries_1.customerQueryKeys.getDeliveryAddresses(), callback);
    const handleOnClickAddress = (address) => {
        setHighlightedAddress(address);
        setEditingAddressId(undefined);
        setCreatingNewAddress(false);
    };
    const isChecked = (deliveryAddressId) => {
        if (creatingNewAddress || (!highlightedAddress && !selectedAddressId)) {
            return false;
        }
        if (!highlightedAddress) {
            return selectedAddressId === deliveryAddressId;
        }
        return (highlightedAddress === null || highlightedAddress === void 0 ? void 0 : highlightedAddress.deliveryAddressId) === deliveryAddressId;
    };
    const getContent = () => ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "3", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingS", children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.delivery.delivery.address.flyout.title`) }), deliveryAddressesData === null || deliveryAddressesData === void 0 ? void 0 : deliveryAddressesData.map(address => ((0, jsx_runtime_1.jsx)(HomeDeliveryAddressCard_1.HomeDeliveryAddressCard, { dataTestId: "hd_saved_address", checked: isChecked(address.deliveryAddressId), isEditing: editingAddressId === address.deliveryAddressId, isPending: updateDeliveryAddressIsPending, error: updateDeliveryAddressError, address: address, addressTitle: addressFormat(address), onEdit: addressId => setEditingAddressId(addressId), onClick: handleOnClickAddress, handleSubmit: handleSubmitUpdateAddress, handleCancel: () => setEditingAddressId(undefined) }, address.deliveryAddressId))), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockEnd: "8", children: creatingNewAddress && !highlightedAddress ? ((0, jsx_runtime_1.jsx)(HomeDeliveryAddressCard_1.HomeDeliveryAddressCard, { dataTestId: "hd_new_address", checked: creatingNewAddress, addressTitle: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.delivery.new.address.card.title`), isPending: addDeliveryAddressIsPending, error: addDeliveryAddressError, handleSubmit: handleSubmitNewAddress, handleCancel: () => setCreatingNewAddress(false) })) : ((0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { theme: "branded", variant: "primary", size: "md", text: t(`${i18nConstants_1.I18N_NAMESPACES.general}:address.add`), iconLeft: {
                        source: andes_icons_1.PlusIcon,
                    }, onClick: handleCreateNewAddress, dataTestId: "hd_add_new_delivery_address" })) })] }));
    const getCTAContent = () => ((0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: true, dataTestId: "hd_select_delivery_address", theme: "branded", text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.delivery.delivery.address.flyout.confirm`), onClick: selectDeliveryAddress }));
    return (0, DeliverySheet_1.DeliverySheet)({
        isOpen,
        onClose: resetDeliveryOption,
        getContent,
        showCTA,
        getCTAContent,
    });
};
exports.HomeDeliveryAddressSelector = HomeDeliveryAddressSelector;
