"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketOverviewPromotionMessage = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const React = require("react");
const react_1 = require("react");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const react_redux_1 = require("react-redux");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const BasketSelector_1 = require("../../redux/basket/selectors/BasketSelector");
const ProductDetailAPI_1 = require("../../api/ProductDetailAPI");
const URLParamUtil_1 = require("../../util/URLParamUtil");
const EventUtil_1 = require("../../util/EventUtil");
const EventEnum_1 = require("../../enums/EventEnum");
const react_i18next_1 = require("react-i18next");
const AdobeTargetConstants_1 = require("../../constants/AdobeTargetConstants");
const YonderlandMediator_1 = require("../../mediator/YonderlandMediator");
const YonderlandMediatorTypes_1 = require("../../mediator/types/YonderlandMediatorTypes");
const BasketConstants_1 = require("../../constants/BasketConstants");
const PROMOTION_MESSAGE_DISCOUNT_VARIABLE = '{DISCOUNT}';
const BasketOverviewPromotionMessage = ({ aemConfiguration, mainWebshopId, preSoldenMessage, messageBoxSpacing, products, }) => {
    var _a, _b;
    const basketTotalAmount = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalQuantityState)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const fictiveWebShop = (0, ConfigurationUtil_1.getFictiveWebShop)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const market = (0, ConfigurationUtil_1.getMarket)(aemConfiguration);
    const [promotionTotalDiscount, setPromotionTotalDiscount] = (0, react_1.useState)(0);
    const { t } = (0, react_i18next_1.useTranslation)(cotopaxi_1.I18N_NAMESPACES.checkout);
    const promotionProducts = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPromotionProductsState)());
    const promotion = (_a = promotionProducts.find(product => !!product.promotion)) === null || _a === void 0 ? void 0 : _a.promotion;
    const recommendedProduct = promotion === null || promotion === void 0 ? void 0 : promotion.recommendedProduct;
    const minimumBasketSize = (_b = promotion === null || promotion === void 0 ? void 0 : promotion.minimumBasketSize) !== null && _b !== void 0 ? _b : 0;
    const isClient = (0, cotopaxi_1.useIsClient)();
    const addVoucherParam = isClient ? (0, URLParamUtil_1.getQuerystringByName)(window.location.search, BasketConstants_1.ADD_VOUCHER_PARAM_KEY) : false;
    const promotionRequirementsFulfilled = basketTotalAmount >= minimumBasketSize;
    const onAddPromotionCalled = () => {
        (0, URLParamUtil_1.removeQuerystringParameter)(BasketConstants_1.ADD_VOUCHER_PARAM_KEY);
        YonderlandMediator_1.default.publish({
            event: AdobeTargetConstants_1.ADOBE_TARGET_PRESOLDEN,
            group: YonderlandMediatorTypes_1.YonderlandMediatorGroup.BASKET,
            data: {
                origin: 'BasketOverviewPromotionMessage',
                hasPreSoldenProduct: true,
                isPreSoldenFulfilled: true,
                hasMultipleProducts: true,
            },
        });
    };
    (0, react_1.useEffect)(() => {
        EventUtil_1.default.listen(EventEnum_1.EventEnum.BASKET_PROMOTION_CALLED, onAddPromotionCalled);
        return () => {
            EventUtil_1.default.remove(EventEnum_1.EventEnum.BASKET_PROMOTION_CALLED, onAddPromotionCalled);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        !!addVoucherParam && !!recommendedProduct && dispatch(BasketActions_creators_1.basketActions.createAddPromotionAction(recommendedProduct));
        if (promotionProducts.length <= 0 || promotionRequirementsFulfilled) {
            return;
        }
        const fetchProductWithPrices = (productId, sku) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            return yield ProductDetailAPI_1.default.getProductDetailPriceBySku(servicesEndpoint, market, mainWebshopId, fictiveWebShop, productId, sku);
        });
        const getAllDiscountPrices = (allProducts) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            return yield Promise.all(allProducts.map((product) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
                const productPrice = yield fetchProductWithPrices(product.productId, product.sku);
                return productPrice ? productPrice.productDiscountAmount * product.quantity : 0;
            })));
        });
        getAllDiscountPrices(promotionProducts).then(response => {
            setPromotionTotalDiscount(response.reduce((acc, price) => acc + price, 0));
        });
    }, [products, basketTotalAmount, promotionProducts, addVoucherParam]);
    if (addVoucherParam && !recommendedProduct && !promotionRequirementsFulfilled) {
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, messageBoxSpacing, { desktop: {
                theme: cotopaxi_1.BoxTheme.QUATERNARY,
            }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE, iconSize: cotopaxi_1.IconSize.MEDIUM, text: t(`${cotopaxi_1.I18N_NAMESPACES.checkout}:checkout.basket.promotion.error.voucher`) }) })));
    }
    if ((0, common_utils_1.isNullOrUndefined)(preSoldenMessage) || (0, common_utils_1.isNullOrUndefined)(recommendedProduct) || promotionRequirementsFulfilled) {
        return null;
    }
    const linkRegexp = /\[(.*)\]/;
    const linkMatch = new RegExp(linkRegexp).exec(preSoldenMessage);
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const link = linkMatch ? linkMatch[1] : '';
    const messageParts = preSoldenMessage
        .replace(PROMOTION_MESSAGE_DISCOUNT_VARIABLE, promotionTotalDiscount > 0 ? `${currency}${promotionTotalDiscount.toFixed(2)}` : '')
        .split(linkRegexp);
    const addPromotionToBasket = (e) => {
        e.preventDefault();
        dispatch(BasketActions_creators_1.basketActions.createAddPromotionAction(recommendedProduct));
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, messageBoxSpacing, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { isTransparent: true, variant: cotopaxi_1.FeedbackMessageVariant.INFO, iconSize: cotopaxi_1.IconSize.MEDIUM, text: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: messageParts.map((part, index) => ((0, jsx_runtime_1.jsx)(React.Fragment, { children: (part === link && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: "", onClick: addPromotionToBasket, children: link }))) ||
                        part }, `${part}_${index}`))) }) }) })));
};
exports.BasketOverviewPromotionMessage = BasketOverviewPromotionMessage;
