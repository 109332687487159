"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreFinderPopup = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const StoreFinderGeneral_1 = require("./store-finder-general/StoreFinderGeneral");
const StoreList_1 = require("./store-list/StoreList");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const react_redux_1 = require("react-redux");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const OsmApi_1 = require("../../../api/open-street-map/OsmApi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ZendeskWidgetUtil_1 = require("../../../util/ZendeskWidgetUtil");
const common_utils_1 = require("@as-react/common-utils");
const StoreUtil_1 = require("../../../util/StoreUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const LocalStorageUtil_1 = require("../../../util/LocalStorageUtil");
const SessionSelector_1 = require("../../../redux/session/selectors/SessionSelector");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const StoreFinderConstants_1 = require("../../../constants/StoreFinderConstants");
const StoreListLoader_1 = require("./store-list-loader/StoreListLoader");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
const andes_react_1 = require("@yonderland/andes-react");
const react_dom_1 = require("react-dom");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const useUserLocation_1 = require("./utils/useUserLocation");
const initLocation = {
    lat: '',
    lng: '',
};
const SIDEBAR_WIDTH = '512px';
const initLocationSearchResult = {
    location: initLocation,
    locationName: '',
    type: common_types_1.LocationSearchType.DEFAULT,
    state: 'INIT',
};
const StoreFinderPopup = ({ handleClosePopup, isOpen, type = common_types_1.StoreFinderType.CLICK_AND_COLLECT, disabledCTA = false, textCTA, title, storeSourceExcluded, onStoreSelect, onSearch, storesQueryResult, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isClient = (0, cotopaxi_1.useIsClient)();
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetIsLoggedIn)());
    const specialtyType = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSpecialtyType)());
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const configuration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(configuration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(configuration);
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(configuration);
    const defaultCountryCode = (0, ConfigurationUtil_1.getDefaultCountryCode)(configuration);
    const countryCodes = (0, ConfigurationUtil_1.getCountryCodes)(configuration);
    const businessUnitId = (0, ConfigurationUtil_1.getBusinessUnitId)(configuration);
    const { isPDPPage } = (0, ConfigurationUtil_1.getPageInfo)(configuration);
    const isSpecialtyTypeProducts = !!specialtyType || (!isPDPPage && !!products.find(product => product.specialtyStoreProduct));
    const isSSEProducts = !!storeSourceExcluded || (!isPDPPage && !!products.find(product => product.sseProduct));
    const [locationSearchResult, setLocationSearchResult] = (0, react_1.useState)(initLocationSearchResult);
    const [isShowStock, setIsShowStock] = (0, react_1.useState)(isSpecialtyTypeProducts && isPDPPage);
    const [userSelectedStore, setUserSelectedStore] = (0, react_1.useState)();
    const [isStoreFinderPopupCTAOpen, setIsStoreFinderPopupCTAOpen] = (0, react_1.useState)(false);
    const [isLocationBlocked, setIsLocationBlocked] = (0, react_1.useState)(false);
    const typeBasketCheckoutOrCC = type === common_types_1.StoreFinderType.CLICK_AND_COLLECT || type === common_types_1.StoreFinderType.BASKET || type === common_types_1.StoreFinderType.CHECKOUT;
    const { getProfile } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { getStoreInformation } = (0, common_queries_1.storeQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { postClickAndCollectStoreId } = (0, common_queries_1.checkoutMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutateAsync: setClickAndCollectStoreId } = (0, react_query_1.useMutation)(postClickAndCollectStoreId);
    const { data: profile } = (0, react_query_1.useQuery)(Object.assign(Object.assign({}, getProfile()), { enabled: Boolean(isLoggedIn) }));
    const { data: userLocation, error: userLocationError, status: userLocationStatus, } = (0, useUserLocation_1.useUserLocation)({
        shouldCheckLocation: isOpen,
    });
    const { data: stores, isLoading: storesIsLoading, error: storesError } = storesQueryResult;
    const { data: storeInformation } = (0, react_query_1.useQuery)(getStoreInformation({
        businessUnitId,
        storeId: (userSelectedStore || {}).storeId,
        weeks: 2,
    }, { enabled: !!(userSelectedStore === null || userSelectedStore === void 0 ? void 0 : userSelectedStore.storeId) }));
    (0, react_1.useEffect)(() => {
        if (!userLocationError) {
            return;
        }
        const { code } = userLocationError;
        if (Number(code) === GeolocationPositionError.PERMISSION_DENIED) {
            setLocationSearchResult(Object.assign(Object.assign({}, initLocationSearchResult), { state: 'SET' }));
            setIsLocationBlocked(true);
        }
        onSearch({
            locationSearch: Object.assign(Object.assign({}, initLocationSearchResult), { state: 'SET' }),
            isShowStock,
            sortBy: getSortBy(),
            selectedStoreId: userSelectedStore === null || userSelectedStore === void 0 ? void 0 : userSelectedStore.storeId,
        });
    }, [userLocationError]);
    (0, react_1.useEffect)(() => {
        if (userLocation &&
            (0, common_types_1.isLocationSearchResult)(userLocation) &&
            userLocation.type !== common_types_1.LocationSearchType.CUSTOM_SEARCH) {
            onSearch({
                locationSearch: userLocation,
                isShowStock,
                sortBy: getSortBy(),
                selectedStoreId: userSelectedStore === null || userSelectedStore === void 0 ? void 0 : userSelectedStore.storeId,
            });
        }
    }, [userLocation]);
    (0, react_1.useEffect)(() => {
        var _a;
        const selectedInStorageStoreId = (_a = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY)) === null || _a === void 0 ? void 0 : _a.storeId;
        const selectedStore = stores === null || stores === void 0 ? void 0 : stores.find(store => store.storeId === selectedInStorageStoreId);
        if (selectedInStorageStoreId && selectedStore) {
            handleStoreSelect(selectedStore);
        }
        else {
            setUserSelectedStore(undefined);
        }
    }, [stores]);
    (0, react_1.useEffect)(() => {
        if (isOpen) {
            setIsShowStock(isSpecialtyTypeProducts && isPDPPage);
            setIsStoreFinderPopupCTAOpen(isOpen);
            ZendeskWidgetUtil_1.default.updateZIndex(1);
        }
    }, [specialtyType, products, isOpen]);
    (0, react_1.useEffect)(() => {
        common_utils_1.CookieUtil.addChangeListener(common_types_1.Cookie.BROWSER_GEOLOCATION, parseCookieLocation);
        return () => {
            common_utils_1.CookieUtil.removeChangeListener(common_types_1.Cookie.BROWSER_GEOLOCATION, parseCookieLocation);
        };
    }, []);
    const resetToInitialState = () => {
        setLocationSearchResult(initLocationSearchResult);
        setIsShowStock(isSpecialtyTypeProducts);
    };
    const onPopoverClose = () => {
        resetToInitialState();
        handleClosePopup();
        setUserSelectedStore(undefined);
    };
    const parseCookieLocation = (value) => {
        let tmpLocationSearchResult = initLocationSearchResult;
        if (value) {
            const parsed = JSON.parse(decodeURIComponent(value));
            if ((0, common_types_1.isLocationSearchResult)(parsed)) {
                tmpLocationSearchResult = parsed;
            }
            else {
                tmpLocationSearchResult.location = parsed;
            }
        }
        setLocationSearchResult(tmpLocationSearchResult);
    };
    const getSortBy = () => {
        var _a;
        if (userLocationError) {
            return 'storeName';
        }
        if (locationSearchResult.type === common_types_1.LocationSearchType.CUSTOM_SEARCH || isShoplanding) {
            return 'distance';
        }
        if (!isLoggedIn) {
            return userLocationError ? 'storeName' : 'distance';
        }
        else {
            return ((_a = profile === null || profile === void 0 ? void 0 : profile.address) === null || _a === void 0 ? void 0 : _a.city) ? 'distance' : userLocationError ? 'storeName' : 'distance';
        }
    };
    const checkLocationByName = (locationName) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const isPostCode = StoreUtil_1.default.isSearchByPostCode(locationName);
        const osmLocation = yield OsmApi_1.default.getLocationFromOSM(isPostCode ? StoreUtil_1.default.formatPostCode(locationName, defaultCountryCode) : locationName, isPostCode ? defaultCountryCode : countryCodes);
        const osmLocationByImportance = osmLocation
            .filter(place => StoreFinderConstants_1.LOCATION_TYPE.includes(place.type))
            .sort((a, b) => b.importance - a.importance);
        if (osmLocationByImportance.length > 0) {
            return {
                location: {
                    lat: osmLocationByImportance[0].lat,
                    lng: osmLocationByImportance[0].lon,
                },
                locationName,
                type: common_types_1.LocationSearchType.CUSTOM_SEARCH,
                state: 'SET',
            };
        }
        return null;
    });
    const handleSubmitLocation = (values, setError) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const { location: formLocation } = values;
        if (!formLocation.trim()) {
            return;
        }
        const locationSearchResult = yield checkLocationByName(formLocation);
        if (locationSearchResult) {
            onSearch({
                locationSearch: locationSearchResult,
                isShowStock,
                sortBy: getSortBy(),
                selectedStoreId: userSelectedStore === null || userSelectedStore === void 0 ? void 0 : userSelectedStore.storeId,
            });
            if (isShoplanding) {
                setLocationSearchResult(locationSearchResult);
            }
            else {
                common_utils_1.CookieUtil.set(common_types_1.Cookie.BROWSER_GEOLOCATION, encodeURIComponent(JSON.stringify(locationSearchResult)), {
                    secure: true,
                });
            }
            LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.SEARCH_STORE_STORAGE_KEY, formLocation);
        }
        else {
            setError('location', { type: 'custom', message: t('product.check.search.noLocationFound') });
        }
    });
    const handleOnStoreSelect = (store) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const { storeId, storeName } = store;
        if (typeBasketCheckoutOrCC) {
            yield setClickAndCollectStoreId(storeId);
        }
        LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY, { storeId, storeName });
        onStoreSelect === null || onStoreSelect === void 0 ? void 0 : onStoreSelect(store);
        closeStoreFinderPopup();
    });
    const handleChangeStock = () => {
        setIsShowStock(prevState => !prevState);
        onSearch({
            locationSearch: userLocation && (0, common_types_1.isLocationSearchResult)(userLocation) ? userLocation : initLocationSearchResult,
            isShowStock: !isShowStock,
            sortBy: getSortBy(),
            selectedStoreId: userSelectedStore === null || userSelectedStore === void 0 ? void 0 : userSelectedStore.storeId,
        });
    };
    const closeStoreFinderPopup = () => {
        onPopoverClose();
        ZendeskWidgetUtil_1.default.resetZIndex();
    };
    const closeStoreFinderPopupCTA = () => setIsStoreFinderPopupCTAOpen(false);
    const handleStoreSelect = (store) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        setUserSelectedStore(store);
    });
    const isClickAndCollectCTAVisible = (store) => {
        if (!store) {
            return false;
        }
        const isStoreAvailableOrSSE = Boolean(store.available || store.availableInSSEStore);
        if (!store.selectable && typeBasketCheckoutOrCC) {
            return false;
        }
        if (isSSEProducts) {
            return !isSpecialtyTypeProducts ? isStoreAvailableOrSSE : Boolean(isStoreAvailableOrSSE && store.specialtyStore);
        }
        return isSpecialtyTypeProducts ? Boolean(store.specialtyStore) : true;
    };
    (0, cotopaxi_1.useLockBodyScroll)(isOpen);
    if (!isClient)
        return null;
    const getCTAContent = () => ((0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { dataTestId: "popup_and_collect", fullWidth: true, theme: "branded", state: (disabledCTA && type !== common_types_1.StoreFinderType.BASKET) ||
            (type === common_types_1.StoreFinderType.IN_STORE_FILTER && !(userSelectedStore === null || userSelectedStore === void 0 ? void 0 : userSelectedStore.itemsCount)) ||
            !userSelectedStore
            ? 'disabled'
            : undefined, text: textCTA ||
            (type === common_types_1.StoreFinderType.IN_STORE_FILTER || type === common_types_1.StoreFinderType.BASKET
                ? t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.lister.inStore.choose`)
                : t(`${i18nConstants_1.I18N_NAMESPACES.store}:store.addToBasket`)), onClick: () => handleOnStoreSelect(userSelectedStore) }));
    const showCTAContent = isOpen &&
        isStoreFinderPopupCTAOpen &&
        !!(stores === null || stores === void 0 ? void 0 : stores.length) &&
        isClickAndCollectCTAVisible(userSelectedStore) &&
        Boolean(userSelectedStore === null || userSelectedStore === void 0 ? void 0 : userSelectedStore.storeId);
    const getCTASidebar = (props) => showCTAContent ? ((0, jsx_runtime_1.jsx)(andes_react_1.Sidebar, Object.assign({ isOpen: true, side: "right", align: "bottom", position: "fixed", animation: "none", zIndex: "modal", hasCloseBtn: false, hasBackdrop: false, fullHeight: false }, props, { children: getCTAContent() }))) : null;
    const getContent = () => ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { as: "h2", variant: "headingS", children: title !== null && title !== void 0 ? title : t('storeFinder.popup.title') }), (0, jsx_runtime_1.jsx)(StoreFinderGeneral_1.default, { isShowStock: isShowStock, isShowStockCheckbox: typeBasketCheckoutOrCC, valuesForm: { location: userLocation && (0, common_types_1.isLocationSearchResult)(userLocation) ? userLocation.locationName : '' }, handleSubmitLocation: handleSubmitLocation, handleChangeStock: handleChangeStock, specialtyType: specialtyType, storeFinderType: type, stores: stores }), locationSearchResult.state === 'ERROR' && userLocationStatus !== 'pending' && ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: t('storeFinder.popup.unable.to.geocode'), variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE })), storesError && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockEnd: "4", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: type === common_types_1.StoreFinderType.BASKET
                        ? t('storeFinder.popup.basket.not.in.stock')
                        : t('storeFinder.popup.not.in.stock'), variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE }) })), storesIsLoading && (0, jsx_runtime_1.jsx)(StoreListLoader_1.StoreListLoader, {}), !!(stores === null || stores === void 0 ? void 0 : stores.length) && !storesIsLoading && ((0, jsx_runtime_1.jsx)(StoreList_1.StoreList, { stores: stores, storeFinderType: type, sortBy: getSortBy(), location: userLocation && (0, common_types_1.isLocationSearchResult)(userLocation) ? userLocation.location : initLocation, isLocationBlocked: isLocationBlocked, handleStoreSelect: handleStoreSelect, userSelectedStoreId: userSelectedStore === null || userSelectedStore === void 0 ? void 0 : userSelectedStore.storeId, selectedStoreInfo: storeInformation, isClickAndCollectCTAVisible: isClickAndCollectCTAVisible }))] }));
    return (0, react_dom_1.createPortal)(isMobile ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.BottomSheet, { isOpen: isOpen, onClose: closeStoreFinderPopup, padding: "none", children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockEnd: userSelectedStore ? '14' : undefined, children: getContent() }) }), getCTASidebar({ customWidth: { width: '100%' } })] })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(andes_react_1.Sidebar, { isOpen: isOpen, onClose: closeStoreFinderPopup, onCloseStart: closeStoreFinderPopupCTA, side: "right", align: "top", position: "fixed", zIndex: "modal", customWidth: { width: SIDEBAR_WIDTH }, hasCloseBtnSpacing: false, footer: {
                content: showCTAContent && getCTAContent(),
                position: 'absolute',
            }, children: getContent() }) })), document.body);
};
exports.StoreFinderPopup = StoreFinderPopup;
