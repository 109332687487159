"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplePayButton = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const HtmlInclude_1 = require("../common/html-include/HtmlInclude");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const ApplePayAPI_1 = require("../../api/ApplePayAPI");
const ApplePayUtil_1 = require("../../util/ApplePayUtil");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const EventUtil_1 = require("../../util/EventUtil");
const EventEnum_1 = require("../../enums/EventEnum");
const StorageKeysConstants_1 = require("../../constants/StorageKeysConstants");
const SessionStorageUtil_1 = require("../../util/SessionStorageUtil");
const useApplePayButton_1 = require("../../hooks/useApplePayButton");
const common_utils_1 = require("@as-react/common-utils");
const ApplePayButton = ({ onClick, totalPrices: { totalBasketDiscountAmount, totalPriceSell, priceTotalBasket, priceDelivery }, hasAndOnlyDigitalProduct, userEmail, clickAndCollectStoreId, storeName, totalVoucherAmount = 0, innerRef, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultCountryCode = (0, ConfigurationUtil_1.getDefaultCountryCode)(aemConfiguration);
    const market = (0, ConfigurationUtil_1.getMarket)(aemConfiguration);
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const { merchantId } = (0, ConfigurationUtil_1.getApplePayConfig)(aemConfiguration);
    const { applePaySupportedNetworks } = (0, useApplePayButton_1.useApplePayButton)();
    let updatedData;
    const getContactFields = (billing = false) => {
        const contactFields = ['name'];
        if (billing) {
            contactFields.push('postalAddress');
            return contactFields;
        }
        if (!hasAndOnlyDigitalProduct) {
            contactFields.push('phone');
        }
        if (!storeName && !hasAndOnlyDigitalProduct) {
            contactFields.push('postalAddress');
        }
        if (!userEmail) {
            contactFields.push('email');
        }
        return contactFields;
    };
    const initiateCheckout = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        yield ApplePayAPI_1.default.initiateCheckout(servicesEndpoint, clickAndCollectStoreId);
    });
    const validateMerchant = (session, validationURL) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const validateMerchantData = {
                url: validationURL,
                merchantIdentifier: merchantId,
                displayName: ApplePayUtil_1.default.defaultConfig.APPLE_PAY_DISPLAY_NAME,
                initiative: ApplePayUtil_1.default.defaultConfig.APPLE_PAY_MERCHANT_INITIATIVE,
                initiativeContext: window.location.hostname,
            };
            const merchantSession = yield ApplePayAPI_1.default.validateMerchant(servicesEndpoint, validateMerchantData, market);
            if (!('merchantIdentifier' in merchantSession &&
                'merchantSessionIdentifier' in merchantSession &&
                ('nOnce' in merchantSession || 'nonce' in merchantSession))) {
                session.abort();
            }
            session.completeMerchantValidation(merchantSession);
        }
        catch (_a) {
            session.abort();
        }
    });
    const updateShippingDetails = (session, contact) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const { newTotal, shippingCost, discount, subTotal, totalVoucherAmount: voucherAmount, } = yield ApplePayAPI_1.default.updateShippingAddress(servicesEndpoint, contact);
            updatedData = Object.assign(Object.assign({}, updatedData), { newTotal: Object.assign(Object.assign({}, updatedData.newTotal), { amount: newTotal.toString() }), newLineItems: [
                    {
                        label: t('buy.payment.applepay.subtotal'),
                        amount: subTotal.toString(),
                    },
                    {
                        label: t('buy.payment.applepay.discount'),
                        amount: discount.toString(),
                    },
                    {
                        label: t('buy.payment.applepay.delivery'),
                        amount: shippingCost.toString(),
                    },
                    {
                        label: t('buy.payment.applepay.voucher'),
                        amount: voucherAmount.toString(),
                    },
                ] });
            delete updatedData.errors;
            session.completeShippingContactSelection(updatedData);
        }
        catch (_b) {
            updatedData.errors = [
                new ApplePayError('shippingContactInvalid', 'countryCode', t('buy.payment.applepay.error.countrycode')),
            ];
            session.completeShippingContactSelection(updatedData);
        }
    });
    const paymentAuthorized = (session, payment) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const updatedPaymentData = Object.assign(Object.assign({}, payment), { shippingContact: userEmail
                ? Object.assign(Object.assign({}, payment.shippingContact), { emailAddress: userEmail }) : payment.shippingContact });
        try {
            const { redirectUrl } = yield ApplePayAPI_1.default.payment(servicesEndpoint, updatedPaymentData);
            if (redirectUrl === null || redirectUrl === void 0 ? void 0 : redirectUrl.includes('payment')) {
                SessionStorageUtil_1.default.setItem(StorageKeysConstants_1.APPLE_PAY_PAYMENT_FAILED, true);
                session.completePayment({
                    status: ApplePaySession.STATUS_FAILURE,
                });
            }
            else {
                session.completePayment({
                    status: ApplePaySession.STATUS_SUCCESS,
                });
            }
            if (redirectUrl) {
                location.href = redirectUrl;
            }
        }
        catch (_c) {
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.APPLE_PAY_PAYMENT_FAILED);
            session.completePayment({
                status: ApplePaySession.STATUS_FAILURE,
            });
        }
    });
    const clickHandler = () => {
        const PAYMENT_REQUEST = Object.assign(Object.assign({ countryCode: defaultCountryCode.toUpperCase(), currencyCode: (0, common_utils_1.getCurrencyISOCode)(currency), merchantCapabilities: ApplePayUtil_1.default.defaultConfig.APPLE_PAY_MERCHANT_CAPABILITIES }, (storeName && {
            shippingMethods: [
                {
                    identifier: ApplePayUtil_1.default.defaultConfig.APPLE_PAY_SHIPPING_METHOD_IDENTIFIER,
                    label: storeName,
                    amount: '0',
                    detail: '',
                },
            ],
        })), { shippingType: storeName
                ? ApplePayUtil_1.default.defaultConfig.APPLE_PAY_SHIPPING_TYPE.storePickup
                : ApplePayUtil_1.default.defaultConfig.APPLE_PAY_SHIPPING_TYPE.shipping, supportedNetworks: applePaySupportedNetworks, requiredBillingContactFields: getContactFields(true), requiredShippingContactFields: getContactFields(), lineItems: [
                {
                    label: t('buy.payment.applepay.subtotal'),
                    amount: totalPriceSell,
                },
                {
                    label: t('buy.payment.applepay.discount'),
                    amount: totalBasketDiscountAmount,
                },
                {
                    label: t('buy.payment.applepay.delivery'),
                    amount: priceDelivery,
                },
                {
                    label: t('buy.payment.applepay.voucher'),
                    amount: totalVoucherAmount.toString(),
                },
            ], total: {
                label: GeneralConstants_1.FASCIA_GROUP[mainWebShop],
                amount: priceTotalBasket,
            } });
        updatedData = {
            newTotal: PAYMENT_REQUEST.total,
            newLineItems: PAYMENT_REQUEST.lineItems,
        };
        const session = new ApplePaySession(ApplePayUtil_1.default.defaultConfig.APPLE_PAY_VERSION, PAYMENT_REQUEST);
        session.begin();
        session.onvalidatemerchant = (event) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            yield initiateCheckout();
            yield validateMerchant(session, event.validationURL);
        });
        session.onshippingcontactselected = (event) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            yield updateShippingDetails(session, event.shippingContact);
        });
        session.onpaymentauthorized = (event) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            yield paymentAuthorized(session, event.payment);
        });
        onClick === null || onClick === void 0 ? void 0 : onClick();
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { innerRef: innerRef, children: [(0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { styleFragment: ApplePayUtil_1.default.getApplePayButtonStyles() }), (0, jsx_runtime_1.jsx)("button", { type: "button", className: "apple-pay-button apple-pay-button-black", onClick: clickHandler })] }));
};
exports.ApplePayButton = ApplePayButton;
