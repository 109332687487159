"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutDeliveryContainer = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const andes_react_1 = require("@yonderland/andes-react");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const andes_icons_1 = require("@yonderland/andes-icons");
const CountrySelector_1 = require("../../components/checkout/delivery/country-selector/CountrySelector");
const react_redux_1 = require("react-redux");
const DeliveryOptions_1 = require("../../components/checkout/delivery/options/DeliveryOptions");
const BuyFormUtil_1 = require("../../util/BuyFormUtil");
const react_query_1 = require("@tanstack/react-query");
const common_queries_1 = require("@as-react/common-queries");
const common_utils_1 = require("@as-react/common-utils");
const BasketSelector_1 = require("../../redux/basket/selectors/BasketSelector");
const CheckoutContext_1 = require("../../context/CheckoutContext");
const BasketUtil_1 = require("../../util/BasketUtil");
const axios_1 = require("axios");
const SagaContextSelectors_1 = require("../../redux/SagaContextSelectors");
const react_1 = require("react");
const DeliveryEVoucher_1 = require("../../components/checkout/delivery/DeliveryEVoucher");
const react_i18next_1 = require("react-i18next");
const CheckoutDeliveryContext_1 = require("../../context/CheckoutDeliveryContext");
const i18nConstants_1 = require("../../constants/i18nConstants");
const SessionStorageUtil_1 = require("../../util/SessionStorageUtil");
const StorageKeysConstants_1 = require("../../constants/StorageKeysConstants");
const DeliveryForm_1 = require("../../components/checkout/delivery/DeliveryForm");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const hasMultipleSelectableDeliveryOptions = (info) => info.deliveryOptions.length && info.deliveryOptions.filter(option => !option.exclusionCausedByProduct).length > 1;
const CheckoutDeliveryContainer = () => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, SagaContextSelectors_1.getRoot)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const lang = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    const [{ info, deliveryCountryId, activeDeliveryOption }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { deliveryForm: { singleDeliveryMessage }, } = (0, CheckoutContext_1.useCheckoutConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const reduxDispatch = (0, react_redux_1.useDispatch)();
    const orderLinesHaveDifferentDeliveryPromises = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketDifferentDeliveryPromisesState)());
    const basketTotalQuantity = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalQuantityState)());
    const { getDeliveryCountries, getDeliveryInfo } = (0, common_queries_1.checkoutQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { postDeliveryCountry } = (0, common_queries_1.checkoutMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { data: deliveryCountries, isLoading: countriesLoading } = (0, react_query_1.useQuery)(getDeliveryCountries({
        select: data => (0, common_utils_1.sortCountriesAlphabetically)(data.countryResourceList, defaultRequestParams.language),
    }));
    const { data: deliveryInfo, error: deliveryInfoError, refetch: refetchGetDeliveryInfo, } = (0, react_query_1.useQuery)(getDeliveryInfo(undefined, { enabled: !deliveryCountryId }));
    const { mutate: mutateDeliveryCountry } = (0, react_query_1.useMutation)(postDeliveryCountry);
    const { postDeliveryOption } = (0, common_queries_1.checkoutMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { mutateAsync: postDeliveryOptionMutation } = (0, react_query_1.useMutation)(postDeliveryOption);
    const updateDeliverOption = (name) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        yield postDeliveryOptionMutation(name);
        reduxDispatch(BasketActions_creators_1.basketActions.createGetAction());
    });
    (0, react_1.useEffect)(() => {
        !!activeDeliveryOption && updateDeliverOption(activeDeliveryOption.name);
    }, [activeDeliveryOption]);
    (0, react_1.useEffect)(() => {
        var _a;
        if (!deliveryInfo) {
            return;
        }
        if (!deliveryInfo.customerDetails) {
            const userData = SessionStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.GUEST_USER_CHECKOUT_DATA);
            if (userData && userData.customerDetails) {
                deliveryInfo.customerDetails = userData.customerDetails;
            }
            if (userData && userData.deliveryAddress) {
                deliveryInfo.deliveryAddress = userData.deliveryAddress;
            }
        }
        const activeDeliveryOption = deliveryInfo.deliveryOptions
            .filter(option => !option.exclusionCausedByProduct)
            .find(option => option.name === deliveryInfo.chosenDeliveryOption);
        if (((_a = deliveryInfo === null || deliveryInfo === void 0 ? void 0 : deliveryInfo.customerDetails) === null || _a === void 0 ? void 0 : _a.idTitle) === -1) {
            deliveryInfo.customerDetails.idTitle = undefined;
        }
        dispatch({ type: 'set_delivery_info', payload: deliveryInfo });
        dispatch({ type: 'set_active_delivery_option', payload: activeDeliveryOption });
        dispatch({ type: 'set_delivery_country_id', payload: deliveryInfo.idCountry });
    }, [deliveryInfo === null || deliveryInfo === void 0 ? void 0 : deliveryInfo.idCountry]);
    if (deliveryInfoError && (0, axios_1.isAxiosError)(deliveryInfoError)) {
        if (BasketUtil_1.default.isBasketNotFound(deliveryInfoError) || BasketUtil_1.default.isBasketNotInitialized(deliveryInfoError)) {
            location.href = `${root}checkout/basket-overview.html`;
        }
    }
    const handleChangeDeliveryCountry = (countryId) => {
        mutateDeliveryCountry(countryId, {
            onSuccess() {
                if (countryId !== info.idCountry) {
                    refetchGetDeliveryInfo();
                }
                dispatch({ type: 'set_delivery_country_id', payload: countryId });
            },
            onError(error) {
                if ((0, axios_1.isAxiosError)(error)) {
                    if (BasketUtil_1.default.isBasketNotInitialized(error)) {
                        location.href = `${root}checkout/basket-overview.html`;
                    }
                }
            },
        });
    };
    const DeliveryHeader = () => ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "4", itemsAlign: "end", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { flex: { grow: 1 }, children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { itemsAlign: "stretch", gap: "3", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingS", children: t('checkout.delivery.title') }), info && info.requiresDelivery && hasMultipleSelectableDeliveryOptions(info) && ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: t('checkout.delivery.choose.delivery.option') }))] }) }), (info === null || info === void 0 ? void 0 : info.requiresDelivery) && ((0, jsx_runtime_1.jsx)(CountrySelector_1.CountrySelector, { countries: deliveryCountries || [], selectedId: deliveryCountryId, loading: countriesLoading, lang: lang, changeDeliveryCountry: handleChangeDeliveryCountry }))] }));
    if (!deliveryInfo) {
        return (0, jsx_runtime_1.jsx)(DeliveryHeader, {});
    }
    const { deliveryOptions, requiresDelivery, chosenDeliveryOption } = deliveryInfo;
    const showSingleDeliveryMessage = basketTotalQuantity > 1 && orderLinesHaveDifferentDeliveryPromises && singleDeliveryMessage && requiresDelivery;
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsx)(DeliveryHeader, {}), showSingleDeliveryMessage && ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { icon: { source: andes_icons_1.TruckIcon }, text: singleDeliveryMessage, status: "success" })), (0, jsx_runtime_1.jsxs)(DeliveryForm_1.DeliverySubmitForm, { children: [!requiresDelivery && (0, jsx_runtime_1.jsx)(DeliveryEVoucher_1.DeliveryEVoucher, {}), requiresDelivery && deliveryOptions.length > 0 && ((0, jsx_runtime_1.jsx)(DeliveryOptions_1.DeliveryOptions, { deliveryOptions: deliveryOptions, chosenDeliveryOption: (0, BuyFormUtil_1.chooseDeliveryOption)(deliveryOptions, info.chosenDeliveryOption || chosenDeliveryOption) }))] })] }));
};
exports.CheckoutDeliveryContainer = CheckoutDeliveryContainer;
