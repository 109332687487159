"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroupContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const asNumeral_1 = require("../../../../vendors/asNumeral");
const PaymentGroupUtil_1 = require("../../../../util/PaymentGroupUtil");
const PaymentGroupContent = ({ group, selected, children, error, hint, submitButtonText, disabled, isSubmitting, innerRef, onSubmit, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const { currency, isShopLanding } = (0, common_components_1.useAemConfigurationContextWrapper)();
    const { contentProps } = (0, andes_react_1.useCollapsible)();
    const infoMessage = (() => {
        var _a;
        const isAllOptionsValid = (0, PaymentGroupUtil_1.isAllPaymentOptionsValid)(group);
        const isEcocheque = group.name === common_types_1.PaymentGroupName.ECOCHEQUE;
        if (!isAllOptionsValid && ((0, PaymentGroupUtil_1.isSingleOption)(group) || isEcocheque)) {
            const paymentOption = group.paymentOptions[0];
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(_a = paymentOption.reasonNotValidForBasket) === null || _a === void 0 ? void 0 : _a.map(error => ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", color: "subdued", children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.payment.${error}`, {
                            currency,
                            minAmount: paymentOption.minBasketCost,
                            maxAmount: paymentOption.maxBasketCost,
                        }) }, error))), paymentOption.exceededWarningBasketCost && !paymentOption.exceededMaxBasketCost && ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", color: "subdued", children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.payment.exceededWarningBasketCost`) }))] }));
        }
        else if (isAllOptionsValid && isEcocheque && group.amountPayableWithOption !== null) {
            return ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { hideIcon: true, status: "sustainability", dataTestId: "ecocheque_amount_payable_message", text: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.group.ecocheque.amount_payable`, {
                    currency,
                    amount: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(group.amountPayableWithOption),
                }), tooltip: isShopLanding
                    ? undefined
                    : {
                        text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:basket.feature.ecocheques_accepted.tooltip`),
                        strategy: 'fixed',
                        hasCloseBtn: false,
                    } }));
        }
        return undefined;
    })();
    return ((0, jsx_runtime_1.jsxs)("div", { ref: innerRef, children: [(children || error || hint || infoMessage) && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: { xs: '3' }, children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "3", children: [hint && ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", color: "subdued", children: hint })), infoMessage, error && (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: error, status: "error", icon: { source: andes_icons_1.WarningIcon } }), children && ((0, jsx_runtime_1.jsx)(andes_react_1.CollapsibleContent, Object.assign({}, contentProps, { isOpen: selected, children: children })))] }) })), selected && ((0, jsx_runtime_1.jsx)(andes_react_1.CheckoutLayout.Sticky, { children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid, { columns: 12, children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, lg: 4 }, offset: { lg: 9 }, children: (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: true, theme: "branded", text: submitButtonText || t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.payment.submit`), state: disabled ? 'disabled' : isSubmitting ? 'loading' : 'interactive', onClick: e => {
                                e.stopPropagation();
                                onSubmit();
                            }, dataTestId: "payment_submit" }) }) }) }))] }));
};
exports.PaymentGroupContent = PaymentGroupContent;
