"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeDeliveryDaySelector = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const react_query_1 = require("@tanstack/react-query");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const react_1 = require("react");
const BuyFormUtil_1 = require("../../../../util/BuyFormUtil");
const DeliverySheet_1 = require("../DeliverySheet");
const DateUtil_1 = require("../../../../util/DateUtil");
const DeliveryDateLabel = ({ date, locale, customWeekday }) => {
    const { weekday, day, month } = (0, DateUtil_1.formatTimeStampToDateParts)(date, locale, {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
    });
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { itemsAlign: "center", contentAlign: "center", gap: "2", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { strong: true, children: [customWeekday || weekday, " "] }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyXS", as: "span", children: `${day} ${month}` })] }));
};
const HomeDeliveryDaySelector = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [{ info, openSelector }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const isOpen = openSelector === 'delivery-day';
    const [selectedDay, setSelectedDay] = (0, react_1.useState)();
    const { getNamedDayDeliveryDates } = (0, common_queries_1.checkoutQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: deliveryDates } = (0, react_query_1.useQuery)(getNamedDayDeliveryDates({
        enabled: (0, BuyFormUtil_1.showDeliveryDates)(info.deliveryOptions),
    }));
    const selectDeliveryDay = () => {
        if (!selectedDay) {
            return;
        }
        dispatch({ type: 'select_delivery_day', payload: selectedDay.date });
        dispatch({ type: 'set_open_selector', payload: undefined });
    };
    const cancelDeliveryDayChange = () => {
        dispatch({ type: 'set_open_selector', payload: undefined });
    };
    const handleClickDate = (deliveryDate) => {
        setSelectedDay(deliveryDate);
    };
    const getContent = () => ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockEnd: "4", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingS", children: t('checkout.delivery.delivery.day.flyout.title') }), (0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "4", children: deliveryDates === null || deliveryDates === void 0 ? void 0 : deliveryDates.map((deliveryDate, index) => ((0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard, { dataTestId: "hd_date_option", state: selectedDay && selectedDay === deliveryDate ? 'active' : 'resting', onClick: () => handleClickDate(deliveryDate), children: (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", cellProps: {
                                align: 'center',
                            }, children: (0, jsx_runtime_1.jsx)(DeliveryDateLabel, { date: deliveryDate.date, locale: aemConfiguration.locale, customWeekday: index === 0 && (0, DateUtil_1.isTomorrow)(deliveryDate.date)
                                    ? t('checkout.delivery.delivery.day.tomorrow')
                                    : undefined }) }) }, deliveryDate.date))) })] }) }));
    const getCTAContent = () => ((0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: true, dataTestId: "hd_select_delivery_date", theme: "branded", text: t('checkout.delivery.delivery.day.flyout.confirm'), onClick: selectDeliveryDay }));
    return (0, DeliverySheet_1.DeliverySheet)({
        isOpen,
        onClose: cancelDeliveryDayChange,
        getContent,
        showCTA: !!selectedDay,
        getCTAContent,
    });
};
exports.HomeDeliveryDaySelector = HomeDeliveryDaySelector;
