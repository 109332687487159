"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentVoucherBlock = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const AnalyticsActions_creators_1 = require("../../../redux/analytics/actions/AnalyticsActions.creators");
const BasketActions_creators_1 = require("../../../redux/basket/actions/BasketActions.creators");
const SagaContextSelectors_1 = require("../../../redux/SagaContextSelectors");
const useOnVoucherChanges_1 = require("../../../hooks/useOnVoucherChanges");
const CheckoutPaymentContext_1 = require("../../../context/CheckoutPaymentContext");
const QueryClientUtil_1 = require("../../../util/QueryClientUtil");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const VoucherBlock_1 = require("../voucher/VoucherBlock");
const PaymentVoucherBlock = () => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const [state] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, SagaContextSelectors_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { postSubmitVoucher } = (0, common_queries_1.checkoutMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutateAsync: postSubmitVoucherMutation } = (0, react_query_1.useMutation)(postSubmitVoucher);
    const dispatch = (0, react_redux_1.useDispatch)();
    const { onVoucherChanges } = (0, useOnVoucherChanges_1.useOnVoucherChanges)();
    const handleVoucherAddSuccess = (code) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        dispatch(AnalyticsActions_creators_1.analyticsActions.voucherAdded(code));
        dispatch(BasketActions_creators_1.basketActions.createGetAction());
        yield QueryClientUtil_1.default.refetchQueries({ queryKey: common_queries_1.checkoutQueryKeys.getPaymentInfo(), stale: false });
        yield onVoucherChanges();
    });
    const handleVoucherSubmit = (code, webcode) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const appliedVoucher = yield postSubmitVoucherMutation({ code, webcode });
        return appliedVoucher;
    });
    const handleVoucherAddError = (message) => {
        message &&
            dispatch(AnalyticsActions_creators_1.analyticsActions.formsErrorMessage({
                ['BuyService._postVoucher']: message,
                ['buy-voucher.voucher-code_1']: message,
            }));
    };
    return ((0, jsx_runtime_1.jsx)(VoucherBlock_1.VoucherBlock, { isPaymentPage: true, onAddError: handleVoucherAddError, onAddSuccess: handleVoucherAddSuccess, onSubmit: handleVoucherSubmit, validateRegex: (_a = state === null || state === void 0 ? void 0 : state.info) === null || _a === void 0 ? void 0 : _a.validateRegex, submitButtonLabel: t('buy.payment.voucher.submit') }));
};
exports.PaymentVoucherBlock = PaymentVoucherBlock;
