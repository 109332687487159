"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProceedCheckoutCTA = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const andes_react_1 = require("@yonderland/andes-react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const GeneralConstants_1 = require("../../../../constants/GeneralConstants");
const BasketSelector_1 = require("../../../../redux/basket/selectors/BasketSelector");
const CheckoutActions_creators_1 = require("../../../../redux/checkout/actions/CheckoutActions.creators");
const ProceedCheckoutCTA = ({ disabled }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const { root } = (0, common_components_1.useAemConfigurationContextWrapper)();
    const isDesktop = (0, andes_react_1.useMediaQueryMatchesOnDesktop)();
    const isMobile = (0, andes_react_1.useMediaQueryMatchesOnMobile)();
    const [isSidebarHidden, setIsSidebarHidden] = (0, react_1.useState)(true);
    const [buttonPosition, setButtonPosition] = (0, react_1.useState)(0);
    const [clientHeight, setClientHeight] = (0, react_1.useState)(0);
    const [proceedButton, setProceedButton] = (0, react_1.useState)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const updatePosition = () => {
        if (proceedButton) {
            const top = proceedButton.getBoundingClientRect().top + proceedButton.getBoundingClientRect().height + window.pageYOffset;
            setButtonPosition(top);
        }
    };
    const handleSetHidden = () => {
        const isHiddenStickyBar = proceedButton && proceedButton.getBoundingClientRect().bottom < window.innerHeight;
        setIsSidebarHidden(Boolean(isHiddenStickyBar));
    };
    const proceedButtonRef = (0, react_1.useCallback)((node) => {
        if (node !== null) {
            setProceedButton(node);
        }
    }, []);
    const updateWithDelay = (0, cotopaxi_1.useDebounce)(() => {
        setClientHeight(window.innerHeight);
        updatePosition();
    }, GeneralConstants_1.DELAY.DELAY_20);
    const onClickCheckout = () => dispatch(CheckoutActions_creators_1.default.createInitAction({ hrefCheckout: `${root}buy.html` }));
    (0, react_1.useEffect)(() => {
        updatePosition();
    }, [proceedButton, products.length, isMobile, isDesktop]);
    (0, react_1.useEffect)(() => {
        updatePosition();
        window.addEventListener('resize', updateWithDelay);
        return () => window.removeEventListener('resize', updatePosition);
    }, []);
    (0, react_1.useEffect)(() => {
        handleSetHidden();
    }, [buttonPosition, clientHeight]);
    (0, cotopaxi_1.useScrollPosition)(handleSetHidden, [buttonPosition]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: true, innerRef: proceedButtonRef, theme: "branded", dataTestId: "checkout_button", state: disabled ? 'disabled' : undefined, text: t('checkout.basket.order'), onClick: onClickCheckout }), !isDesktop && !isSidebarHidden && ((0, jsx_runtime_1.jsx)(andes_react_1.Sidebar, { isOpen: true, side: "right", align: "bottom", position: "fixed", animation: "none", zIndex: "sticky", hasCloseBtn: false, hasBackdrop: false, fullHeight: false, customWidth: "100%", children: (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: true, theme: "branded", dataTestId: "checkout_button", state: disabled ? 'disabled' : undefined, text: t('checkout.basket.order'), onClick: onClickCheckout }) }))] }));
};
exports.ProceedCheckoutCTA = ProceedCheckoutCTA;
