"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketOverviewVoucher = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const VoucherBlock_1 = require("../voucher/VoucherBlock");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const SagaContextSelectors_1 = require("../../../redux/SagaContextSelectors");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
const BasketActions_creators_1 = require("../../../redux/basket/actions/BasketActions.creators");
const AnalyticsActions_creators_1 = require("../../../redux/analytics/actions/AnalyticsActions.creators");
const ComponentConfigContext_1 = require("../../../context/ComponentConfigContext");
const BasketOverviewVoucher = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const { totalMiniBlock } = (0, ComponentConfigContext_1.useBasketOverviewConfig)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, SagaContextSelectors_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { isShopLanding } = aemConfiguration;
    const isDesktop = (0, andes_react_1.useMediaQueryMatchesOnDesktop)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const validateRegex = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketValidateRegexState)());
    const { postVoucher } = (0, common_queries_1.basketMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutateAsync: postVoucherMutation } = (0, react_query_1.useMutation)(postVoucher);
    const handleVoucherSubmit = (code, webcode) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        return yield postVoucherMutation({ code, webcode });
    });
    const handleVoucherAddSuccess = () => {
        dispatch(BasketActions_creators_1.basketActions.createGetAction());
    };
    const handleVoucherAddError = (message) => {
        message && dispatch(AnalyticsActions_creators_1.analyticsActions.formsErrorMessage({ voucher_code: message }));
    };
    const handleVoucherFieldsError = (errors) => {
        dispatch(AnalyticsActions_creators_1.analyticsActions.formsErrorMessage(errors));
    };
    const openByDefault = !(isDesktop
        ? totalMiniBlock === null || totalMiniBlock === void 0 ? void 0 : totalMiniBlock.isVoucherCodeCollapsedOnDesktop
        : totalMiniBlock === null || totalMiniBlock === void 0 ? void 0 : totalMiniBlock.isVoucherCodeCollapsedOnMobileTablet);
    if (isShopLanding)
        return null;
    return ((0, jsx_runtime_1.jsx)(VoucherBlock_1.VoucherBlock, { isOpenByDefault: openByDefault, onAddError: handleVoucherAddError, onAddSuccess: handleVoucherAddSuccess, onSubmit: handleVoucherSubmit, onFieldsError: handleVoucherFieldsError, validateRegex: validateRegex, submitButtonLabel: t('checkout.basket.voucher.submit') }));
};
exports.BasketOverviewVoucher = BasketOverviewVoucher;
