"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyConfirmationDeliveryMethodInfo = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const BuyConfirmationDeliveryStoreInfo_1 = require("./BuyConfirmationDeliveryStoreInfo");
const BuyConfirmationOpeningHours_1 = require("./BuyConfirmationOpeningHours");
const StoreUtil_1 = require("../../../util/StoreUtil");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const BuyAddressCard_1 = require("../forms/buy-address-card/BuyAddressCard");
const BuyFormUtil_1 = require("../../../util/BuyFormUtil");
const DateUtil_1 = require("../../../util/DateUtil");
const INTL_DATE_FORMAT = {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
};
const BuyConfirmationDeliveryMethodInfo = ({ deliveryInfo, order, store, isShoplanding, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    const locale = (0, ConfigurationUtil_1.getLocale)(aemConfiguration);
    const pickUpPoint = order.deliveryOption === common_types_1.DeliveryOptionsEnum.PICKUP_POINT;
    const collectStore = order.deliveryOption === common_types_1.DeliveryOptionsEnum.COLLECT_STORE;
    const collectHQ = order.deliveryOption === common_types_1.DeliveryOptionsEnum.COLLECT_HQ;
    const homeDelivery = (0, BuyFormUtil_1.isDaysOption)(order.deliveryOption);
    const getDeliveryInfoAndText = () => {
        if (order.deliveryOption === common_types_1.DeliveryOptionsEnum.COLLECT_STORE) {
            return {
                before: deliveryInfo.clickAndCollectDeliveryInfo,
                after: deliveryInfo.emailPickupTextShop,
            };
        }
        if (order.deliveryOption === common_types_1.DeliveryOptionsEnum.PICKUP_POINT) {
            return {
                before: deliveryInfo.pickupPointDeliveryInfo,
                after: deliveryInfo.emailPickupTextPickupPoint,
            };
        }
        if (order.deliveryOption === common_types_1.DeliveryOptionsEnum.COLLECT_HQ) {
            return {
                before: `${t('buy:buy.payment.delivery.information.header.collecthq')}:`,
                after: '',
            };
        }
        return {
            before: deliveryInfo.homeDeliveryInfo,
            after: deliveryInfo.emailDispatchText,
        };
    };
    const hasDeliveryDate = order.deliveryDate || (order.deliveryDateFrom && order.deliveryDateTo);
    const getDeliveryMessage = () => {
        var _a, _b;
        const deliveryMessage = order.deliveryMessage ? order.deliveryMessage : '';
        if (collectStore && store) {
            return `${StoreUtil_1.default.getStoreName(store, mainWebShop, t)} ${deliveryMessage}`;
        }
        const pickUpPointMessage = pickUpPoint
            ? `${(_a = order.deliveryAddressResource) === null || _a === void 0 ? void 0 : _a.organisationName} ${(_b = order.deliveryAddressResource) === null || _b === void 0 ? void 0 : _b.locationName}. `
            : '';
        if (order.deliveryDate) {
            return `${pickUpPointMessage}${t('buy:buy.confirmation.delivery.information.promise.date', {
                date: (0, DateUtil_1.formatTimeStampToDateString)(order.deliveryDate, locale, INTL_DATE_FORMAT),
            })}.`;
        }
        if (order.deliveryDateFrom && order.deliveryDateTo) {
            if (order.deliveryDateFrom === order.deliveryDateTo) {
                return `${pickUpPointMessage}${t('buy:buy.confirmation.delivery.information.promise.date', {
                    date: (0, DateUtil_1.formatTimeStampToDateString)(order.deliveryDateTo, locale, INTL_DATE_FORMAT),
                })}.`;
            }
            return `${pickUpPointMessage}${t('buy:buy.confirmation.delivery.information.promise.daterange', {
                dateFrom: (0, DateUtil_1.formatTimeStampToDateString)(order.deliveryDateFrom, locale, INTL_DATE_FORMAT),
                dateTo: (0, DateUtil_1.formatTimeStampToDateString)(order.deliveryDateTo, locale, INTL_DATE_FORMAT),
            })}.`;
        }
        return deliveryMessage;
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, tablet: { spacing: { marginBottom: isShoplanding ? 2 : 4 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, children: isShoplanding ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [deliveryInfo.deliveryPromise && `${deliveryInfo.deliveryPromise} `, (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, color: cotopaxi_1.TextColor.POSITIVE, type: cotopaxi_1.TextType.CONTENT, children: getDeliveryMessage() })] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(!hasDeliveryDate || pickUpPoint) && getDeliveryInfoAndText().before, ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, color: cotopaxi_1.TextColor.POSITIVE, type: cotopaxi_1.TextType.CONTENT, children: getDeliveryMessage() }), ' ', getDeliveryInfoAndText().after] })) }) }), !isShoplanding && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!collectStore && !collectHQ && ((0, jsx_runtime_1.jsx)(BuyAddressCard_1.BuyAddressCard, { address: order.deliveryAddressResource, textType: cotopaxi_1.TextType.CONTENT, pickUpPoint: pickUpPoint, emphasizedLocationName: pickUpPoint, title: homeDelivery ? t('buy.confirmation.delivery.address') : null })), pickUpPoint && order.locationDTO && (0, jsx_runtime_1.jsx)(BuyConfirmationOpeningHours_1.default, { point: order.locationDTO }), collectStore && store && (0, jsx_runtime_1.jsx)(BuyConfirmationDeliveryStoreInfo_1.BuyConfirmationDeliveryStoreInfo, { store: store })] }))] }));
};
exports.BuyConfirmationDeliveryMethodInfo = BuyConfirmationDeliveryMethodInfo;
