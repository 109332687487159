"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerDetailsPreview = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const CheckoutDeliveryContext_1 = require("../../../context/CheckoutDeliveryContext");
const CustomerUtil_1 = require("../../../util/CustomerUtil");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const DeliveryPreselectedCardView_1 = require("./DeliveryPreselectedCardView");
const react_hook_form_1 = require("react-hook-form");
const CustomerDetailsPreview = ({ onEdit }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const lang = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout]);
    const [{ info }] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { getTitles } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: customerTitles } = (0, react_query_1.useQuery)(getTitles({ lang }));
    const values = (0, react_hook_form_1.useFormContext)().getValues();
    if (!info.customerDetails) {
        return null;
    }
    const customerNames = values || CustomerUtil_1.default.getCustomerNames(info);
    const getCustomerTitle = (id) => {
        var _a;
        if (!customerTitles) {
            return '';
        }
        return ((_a = customerTitles.options.find(title => title.value === id)) === null || _a === void 0 ? void 0 : _a.label) || '';
    };
    const getCustomerDetails = () => `${getCustomerTitle(customerNames.idTitle)} ${customerNames.firstName} ${customerNames.middleName} ${customerNames.lastName}`;
    return ((0, jsx_runtime_1.jsx)(DeliveryPreselectedCardView_1.DeliveryPreselectedCardView, { dataTestId: "customer_details_card", header: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", strong: true, children: t('checkout:checkout.delivery.customer.details.title') }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: getCustomerDetails() })] }), onEdit: onEdit }));
};
exports.CustomerDetailsPreview = CustomerDetailsPreview;
