"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutPaymentContainer = exports.AMEX_METHOD = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const react_query_1 = require("@tanstack/react-query");
const react_1 = require("react");
const andes_react_1 = require("@yonderland/andes-react");
const common_types_1 = require("@as-react/common-types");
const PaymentGroups_1 = require("../../components/checkout/payment/PaymentGroups");
const i18nConstants_1 = require("../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const axios_1 = require("axios");
const BasketUtil_1 = require("../../util/BasketUtil");
const react_redux_1 = require("react-redux");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const AnalyticsEnums_1 = require("../../enums/AnalyticsEnums");
const CheckoutPaymentContext_1 = require("../../context/CheckoutPaymentContext");
const ApplePayUtil_1 = require("../../util/ApplePayUtil");
const useABTest_1 = require("../../hooks/useABTest");
const PaymentNotification_1 = require("../../components/checkout/payment/PaymentNotification");
const PayByVoucher_1 = require("../../components/checkout/payment/PayByVoucher");
const StorageKeysConstants_1 = require("../../constants/StorageKeysConstants");
const LocalStorageUtil_1 = require("../../util/LocalStorageUtil");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const PaymentVoucherBlock_1 = require("../../components/checkout/payment/PaymentVoucherBlock");
exports.AMEX_METHOD = 'AMEX';
const removeAmexPaymentOption = (groups) => {
    const creditCardGroup = groups.find(group => group.name === common_types_1.PaymentGroupName.CREDITCARD);
    if (creditCardGroup) {
        creditCardGroup.paymentOptions = creditCardGroup === null || creditCardGroup === void 0 ? void 0 : creditCardGroup.paymentOptions.filter(option => option.name !== exports.AMEX_METHOD);
    }
    return groups;
};
const CheckoutPaymentContainer = () => {
    const [isSavePointCreated, setIsSavePointCreated] = (0, react_1.useState)(false);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const disableAmexPaymentCookie = (0, useABTest_1.useABTest)(common_types_1.ABTestCookie.DISABLE_AMEX_PAYMENT);
    const [, dispatch] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const reduxDispatch = (0, react_redux_1.useDispatch)();
    const { getPaymentInfo } = (0, common_queries_1.checkoutQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { postSavePoint } = (0, common_queries_1.basketMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { mutate: mutatePostSavePoint } = (0, react_query_1.useMutation)(postSavePoint);
    const { data: paymentInfo, error: paymentInfoError } = (0, react_query_1.useQuery)(getPaymentInfo({
        select: data => {
            if (!data.paymentGroups) {
                return data;
            }
            let groups = data.paymentGroups.filter(group => group.name === common_types_1.PaymentGroupName.APPLEPAY ? ApplePayUtil_1.default.canMakePayments() : true);
            if (disableAmexPaymentCookie) {
                groups = removeAmexPaymentOption(groups);
            }
            data.paymentGroups = groups;
            return Object.assign(Object.assign({}, data), { paymentGroups: groups });
        },
    }));
    if (paymentInfoError && (0, axios_1.isAxiosError)(paymentInfoError)) {
        if (BasketUtil_1.default.isBasketNotFound(paymentInfoError) ||
            BasketUtil_1.default.isBasketNotInitialized(paymentInfoError) ||
            BasketUtil_1.default.isCheckoutStepInvalid(paymentInfoError)) {
            location.href = `${root}checkout/basket-overview.html`;
        }
    }
    (0, react_1.useEffect)(() => {
        reduxDispatch(BasketActions_creators_1.basketActions.createGetAction());
        reduxDispatch(AnalyticsActions_creators_1.analyticsActions.checkoutType(isLoggedIn ? AnalyticsEnums_1.CheckoutType.LOGGED_IN : AnalyticsEnums_1.CheckoutType.GUEST));
    }, []);
    (0, react_1.useEffect)(() => {
        if (!paymentInfo) {
            return;
        }
        const paymentData = Object.assign({}, paymentInfo);
        if (!isSavePointCreated && paymentInfo && !paymentInfo.chosenPaymentOptionId) {
            mutatePostSavePoint(undefined, {
                onSuccess() {
                    setIsSavePointCreated(true);
                },
            });
        }
        if (mainWebShop === common_types_1.MainWebShop.COTSWOLD &&
            (paymentInfo === null || paymentInfo === void 0 ? void 0 : paymentInfo.paymentGroups) &&
            !paymentInfo.chosenPaymentOptionId &&
            !paymentInfo.chosenPaymentOption &&
            !LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.CHOSEN_PAYMENT_OPTION)) {
            const creditCardGroup = paymentInfo.paymentGroups.find(group => group.name === common_types_1.PaymentGroupName.CREDITCARD);
            if (creditCardGroup && creditCardGroup.paymentOptions[0]) {
                paymentData.chosenPaymentOptionId = creditCardGroup.paymentOptions[0].paymentOptionId;
                paymentData.chosenPaymentOption = creditCardGroup.name;
            }
        }
        else if ((paymentInfo === null || paymentInfo === void 0 ? void 0 : paymentInfo.paymentGroups) && !paymentInfo.chosenPaymentOptionId && !paymentInfo.chosenPaymentOption) {
            const storageGroupName = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.CHOSEN_PAYMENT_OPTION);
            if (storageGroupName) {
                const group = paymentInfo.paymentGroups.find(group => group.name.replace(/\s+/g, '') === storageGroupName);
                if (group && group.paymentOptions[0]) {
                    paymentData.chosenPaymentOptionId = group.paymentOptions[0].paymentOptionId;
                    paymentData.chosenPaymentOption = group.name;
                }
            }
        }
        dispatch({ type: 'set_payment_info', payload: paymentData });
    }, [paymentInfo]);
    if (!paymentInfo) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "3", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingS", children: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.progress.payment`) }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.choose.payment.option`) }), (0, jsx_runtime_1.jsx)(PaymentNotification_1.PaymentNotification, {})] }), paymentInfo.paymentGroups && ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [mainWebShop === common_types_1.MainWebShop.COTSWOLD ? (0, jsx_runtime_1.jsx)(PaymentGroups_1.PaymentGroups, {}) : (0, jsx_runtime_1.jsx)(PaymentVoucherBlock_1.PaymentVoucherBlock, {}), (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), mainWebShop === common_types_1.MainWebShop.COTSWOLD ? (0, jsx_runtime_1.jsx)(PaymentVoucherBlock_1.PaymentVoucherBlock, {}) : (0, jsx_runtime_1.jsx)(PaymentGroups_1.PaymentGroups, {})] })), paymentInfo.fullyPaid && (0, jsx_runtime_1.jsx)(PayByVoucher_1.PayByVoucher, {})] }));
};
exports.CheckoutPaymentContainer = CheckoutPaymentContainer;
