"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartTotalBlock = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const andes_react_1 = require("@yonderland/andes-react");
const common_queries_1 = require("@as-react/common-queries");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const react_query_1 = require("@tanstack/react-query");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const CartTotal_1 = require("../../../cart-total/CartTotal");
const BasketSelector_1 = require("../../../../redux/basket/selectors/BasketSelector");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const BasketActions_creators_1 = require("../../../../redux/basket/actions/BasketActions.creators");
const DeliveryPromise_1 = require("./DeliveryPromise");
const ComponentConfigContext_1 = require("../../../../context/ComponentConfigContext");
const DeliveryPromiseUtil_1 = require("../../../../util/DeliveryPromiseUtil");
const StoreUtil_1 = require("../../../../util/StoreUtil");
const AnalyticsActions_creators_1 = require("../../../../redux/analytics/actions/AnalyticsActions.creators");
const ProceedCheckoutCTA_1 = require("./ProceedCheckoutCTA");
const TermsAndConditions_1 = require("./TermsAndConditions");
const ApplePayCTA_1 = require("./ApplePayCTA");
const CheckoutDisabledMessage_1 = require("./CheckoutDisabledMessage");
const CartTotalBlock = ({ isApplePayAvailable }) => {
    var _a, _b, _c;
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.product, i18nConstants_1.I18N_NAMESPACES.general]);
    const isMobile = (0, andes_react_1.useMediaQueryMatchesOnMobile)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const businessUnitId = (0, ConfigurationUtil_1.getBusinessUnitId)(aemConfiguration);
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const { general: { mobileNDDLocation }, } = (0, ComponentConfigContext_1.useBasketOverviewConfig)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const vouchers = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketVouchersState)());
    const pricing = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPricingState)());
    const totalQuantity = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalQuantityState)());
    const deliveryInfo = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketDeliveryInfoState)());
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const clickAndCollectStoreId = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketClickAndCollectStoreIdState)());
    const { getStoreInformation } = (0, common_queries_1.storeQueries)({ servicesEndpoint, defaultRequestParams });
    const { deleteVoucher } = (0, common_queries_1.basketMutations)({ defaultRequestParams, servicesEndpoint });
    const { mutate: deleteVoucherMutation } = (0, react_query_1.useMutation)(deleteVoucher);
    const hasNDDMessage = !!((_a = deliveryInfo.deliveryMessages) === null || _a === void 0 ? void 0 : _a[common_types_1.DeliveryOptionsEnum.NEXT_DAY]) ||
        !!((_b = deliveryInfo.deliveryMessages) === null || _b === void 0 ? void 0 : _b[common_types_1.DeliveryOptionsEnum.NAMED_DAY]);
    const showDeliveryPromise = !isMobile || !hasNDDMessage || mobileNDDLocation !== 'topBasketPage';
    const isCheckoutFlowDisabled = (0, react_1.useMemo)(() => products.some(product => (0, DeliveryPromiseUtil_1.isDeliveryMessageCollectStoreOrHQ)(deliveryInfo.deliveryMessages)
        ? product.ccExcluded ||
            (product.sseProduct && !product.availableInSSEStore) ||
            (product.specialtyStoreProduct && !product.availableInSpecialtyStore)
        : product.sseProduct), [products]);
    const { data: storeName } = (0, react_query_1.useQuery)(getStoreInformation({ businessUnitId, storeId: clickAndCollectStoreId }, {
        enabled: !!clickAndCollectStoreId &&
            (!!((_c = deliveryInfo.deliveryMessages) === null || _c === void 0 ? void 0 : _c[common_types_1.DeliveryOptionsEnum.COLLECT_STORE]) || isCheckoutFlowDisabled),
        select: storeInformation => storeInformation ? StoreUtil_1.default.getStoreName(storeInformation, mainWebShop, t) : undefined,
    }));
    const handleDeleteVoucher = (barcode) => {
        deleteVoucherMutation(barcode, {
            onSuccess: () => dispatch(BasketActions_creators_1.basketActions.createGetAction()),
        });
    };
    const handleChangeStore = () => {
        dispatch(BasketActions_creators_1.basketActions.createCCPopupOpenAction());
        dispatch(AnalyticsActions_creators_1.analyticsActions.showStoreStock());
    };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { shadow: "lg", borderRadius: "lg", overflow: "hidden", backgroundColor: "neutral", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { padding: "4", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleL", children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.title`) }), (0, jsx_runtime_1.jsx)(CartTotal_1.CartTotal, Object.assign({ vouchers: vouchers }, pricing, { totalQuantity: totalQuantity, onDeleteVoucher: handleDeleteVoucher, hideRemoveVoucherButton: false })), showDeliveryPromise && ((0, jsx_runtime_1.jsx)(DeliveryPromise_1.DeliveryPromise, { deliveryStoreName: storeName, handleChangeStore: handleChangeStore, isCheckoutFlowDisabled: isCheckoutFlowDisabled })), (0, jsx_runtime_1.jsx)(ProceedCheckoutCTA_1.ProceedCheckoutCTA, { disabled: isCheckoutFlowDisabled }), isApplePayAvailable && pricing.priceTotalBasket > 0 && !isCheckoutFlowDisabled && ((0, jsx_runtime_1.jsx)(ApplePayCTA_1.ApplePayCTA, { deliveryStoreName: storeName })), isCheckoutFlowDisabled && (0, jsx_runtime_1.jsx)(CheckoutDisabledMessage_1.CheckoutDisabledMessage, {})] }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "2", children: (0, jsx_runtime_1.jsx)(TermsAndConditions_1.TermsAndConditions, {}) })] }));
};
exports.CartTotalBlock = CartTotalBlock;
