"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistrationStep = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const RegistrationForm_1 = require("../forms/registration/RegistrationForm");
const DateUtil_1 = require("../../../../../util/DateUtil");
const ConfigurationUtil_1 = require("../../../../../util/ConfigurationUtil");
const AddressUtil_1 = require("../../../../../util/AddressUtil");
const CustomerApi_1 = require("../../../../../api/customer/CustomerApi");
const GeneralConstants_1 = require("../../../../../constants/GeneralConstants");
const ScrollUtil_1 = require("../../../../../util/ScrollUtil");
const GeneralUtil_1 = require("../../../../../util/GeneralUtil");
const NavigationUtil_1 = require("../../../../../util/NavigationUtil");
const i18nConstants_1 = require("../../../../../constants/i18nConstants");
const AuthActions_creators_1 = require("../../../../../redux/authentication/actions/AuthActions.creators");
const AuthSelectors_1 = require("../../../../../redux/authentication/selectors/AuthSelectors");
const SessionStorageUtil_1 = require("../../../../../util/SessionStorageUtil");
const StorageKeysConstants_1 = require("../../../../../constants/StorageKeysConstants");
const RegistrationStep = (_a) => {
    var { onSuccess } = _a, props = tslib_1.__rest(_a, ["onSuccess"]);
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    const [submitButtonState, setSubmitButtonState] = (0, react_1.useState)(cotopaxi_1.ButtonState.INTERACTIVE);
    const [errorFeedback, setErrorFeedback] = (0, react_1.useState)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const lang = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const guestError = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetErrorGuestState)());
    const loadingGuest = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginGuestLoading)());
    const handleSubmitActivation = (_b) => tslib_1.__awaiter(void 0, [_b], void 0, function* ({ email }) {
        try {
            setErrorFeedback(undefined);
            setSubmitButtonState(cotopaxi_1.ButtonState.LOADING);
            yield CustomerApi_1.default.emailExists(servicesEndpoint, defaultRequestParameters, email, true);
            setSubmitButtonState(cotopaxi_1.ButtonState.FINISHED);
            yield (0, GeneralUtil_1.mimicDelay)();
            window.location.href = (0, NavigationUtil_1.getHomepageUrl)(root);
        }
        catch (_c) {
            setErrorFeedback(t(common_types_1.ErrorMessage.GENERAL_ACCOUNT));
            setSubmitButtonState(cotopaxi_1.ButtonState.INTERACTIVE);
        }
    });
    const handleSubmitRegister = (values) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const customerData = Object.assign({ email: values.email, password: values.password, idTitle: Number(values.title), firstName: values.firstName || '', lastName: values.lastName || '', middleName: values.middleName, gender: values.gender || '', dateOfBirth: values.dateOfBirth
                ? (0, DateUtil_1.formatDate)(values.dateOfBirth, GeneralConstants_1.DateFormat.DD_MM_YYYY.value)
                : values.dateOfBirth, mobilePhoneNumber: values.mobilePhoneNumber, language: lang, preference: {
                preference: false,
                exploreMoreNewsletter: values.newsletter,
            }, address: (0, AddressUtil_1.prepareAddressByCountry)(values.address) }, (values.invoice
            ? {
                finance: Object.assign(Object.assign({}, values.finance), { needInvoice: true, vatNumber: values.vatNumber, companyName: values.companyName, department: values.departmentName }),
            }
            : {}));
        try {
            setErrorFeedback(undefined);
            setSubmitButtonState(cotopaxi_1.ButtonState.LOADING);
            yield CustomerApi_1.default.create(servicesEndpoint, defaultRequestParameters, customerData);
            setSubmitButtonState(cotopaxi_1.ButtonState.FINISHED);
            onSuccess(values.email, values.password);
        }
        catch (_d) {
            setErrorFeedback(t(common_types_1.ErrorMessage.GENERAL_ACCOUNT));
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.querySelector(`[name="${RegistrationForm_1.REGISTRATION_FORM_NAME}"]`));
            setSubmitButtonState(cotopaxi_1.ButtonState.INTERACTIVE);
        }
    });
    const handleSubmitGuest = (values) => {
        const customerDetails = {
            firstName: values.firstName || '',
            lastName: values.lastName || '',
            middleName: values.middleName,
            idTitle: 1,
            emailOnly: true,
        };
        const deliveryAddress = Object.assign(Object.assign({}, (0, AddressUtil_1.prepareAddressByCountry)(values.address, '')), { companyName: values.invoice ? values.companyName : '', addToProfile: false, idTitle: Number(values.title), firstName: values.firstName || '', lastName: values.lastName || '', middleName: values.middleName, phoneNumber: values.mobilePhoneNumber });
        SessionStorageUtil_1.default.setItem(StorageKeysConstants_1.GUEST_USER_CHECKOUT_DATA, { customerDetails, deliveryAddress });
        setErrorFeedback(undefined);
        dispatch(AuthActions_creators_1.authActions.createLoginGuestAction(values.email));
    };
    const handleSubmit = (values, type) => type === RegistrationForm_1.SubmitType.ACTIVATION
        ? handleSubmitActivation(values)
        : type === RegistrationForm_1.SubmitType.REGISTRATION
            ? handleSubmitRegister(values)
            : handleSubmitGuest(values);
    (0, react_1.useEffect)(() => {
        setSubmitButtonState(loadingGuest ? cotopaxi_1.ButtonState.LOADING : cotopaxi_1.ButtonState.INTERACTIVE);
    }, [loadingGuest]);
    (0, react_1.useEffect)(() => {
        if (guestError) {
            setErrorFeedback(t(guestError));
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.querySelector(`[name="${RegistrationForm_1.REGISTRATION_FORM_NAME}"]`));
            setSubmitButtonState(cotopaxi_1.ButtonState.INTERACTIVE);
        }
        else {
            setErrorFeedback(undefined);
        }
    }, [guestError]);
    return ((0, jsx_runtime_1.jsx)(RegistrationForm_1.RegistrationFrom, Object.assign({}, props, { onSubmit: handleSubmit, submitButtonState: submitButtonState, errorFeedback: errorFeedback })));
};
exports.RegistrationStep = RegistrationStep;
