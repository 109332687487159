"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_router_1 = require("react-router");
const common_queries_1 = require("@as-react/common-queries");
const SessionActions_creators_1 = require("../../redux/session/actions/SessionActions.creators");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const RouterSelector_1 = require("../../redux/router/selectors/RouterSelector");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const ScrollUtil_1 = require("../../util/ScrollUtil");
const GeneralUtil_1 = require("../../util/GeneralUtil");
const LockedBasketNotificationMessage_1 = require("../../components/notification-message/LockedBasketNotificationMessage");
const CheckoutLoginContainer_1 = require("./CheckoutLoginContainer");
const CheckoutContext_1 = require("../../context/CheckoutContext");
const CheckoutDeliveryContainer_1 = require("./CheckoutDeliveryContainer");
const CheckoutDeliveryContext_1 = require("../../context/CheckoutDeliveryContext");
const CheckoutLayoutContainer_1 = require("./CheckoutLayoutContainer");
const CheckoutPaymentContainer_1 = require("./CheckoutPaymentContainer");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const react_query_1 = require("@tanstack/react-query");
const useSaferpayDeviceFingerPrinting_1 = require("../../hooks/useSaferpayDeviceFingerPrinting");
const CheckoutPaymentContext_1 = require("../../context/CheckoutPaymentContext");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const CheckoutStepContext_1 = require("../../context/CheckoutStepContext");
const URLParamUtil_1 = require("../../util/URLParamUtil");
const CheckoutContainer = props => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const { rootWithLanguageSuffix, root, isInAuthorMode } = aemConfiguration;
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { deviceFingerPrinting } = (0, ConfigurationUtil_1.getSaferpayConfig)(aemConfiguration);
    const isFirstRender = (0, react_1.useRef)(true);
    const [shouldRender, setShouldRender] = (0, react_1.useState)(false);
    const sessionState = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetSessionState)());
    const pathname = (0, react_redux_1.useSelector)((0, RouterSelector_1.makeGetPathname)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const history = (0, react_router_1.useHistory)();
    const { getBasketBasic } = (0, common_queries_1.basketQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { data: basketBasic, refetch: basketBasicRefetch } = (0, react_query_1.useQuery)(getBasketBasic());
    (0, react_1.useEffect)(() => {
        dispatch(SessionActions_creators_1.default.createInitAction());
        dispatch(BasketActions_creators_1.basketActions.createGetAction());
    }, []);
    (0, react_1.useEffect)(() => {
        sessionState.authorities && !shouldRender && setShouldRender(true);
    }, [sessionState, shouldRender]);
    (0, react_1.useEffect)(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        basketBasicRefetch();
        const scroll = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            yield (0, GeneralUtil_1.mimicDelay)(GeneralConstants_1.DELAY.DELAY_0);
            ScrollUtil_1.ScrollUtil.scrollToTop();
        });
        scroll();
    }, [pathname]);
    (0, react_1.useEffect)(() => {
        if (!(basketBasic === null || basketBasic === void 0 ? void 0 : basketBasic.basketId)) {
            return;
        }
        const loadDeviceFingerPrintingScript = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            yield (0, useSaferpayDeviceFingerPrinting_1.useSaferpayDeviceFingerPrinting)(deviceFingerPrinting.dfpBaseUrl, basketBasic.basketId, deviceFingerPrinting.instanceId);
        });
        loadDeviceFingerPrintingScript();
    }, [basketBasic === null || basketBasic === void 0 ? void 0 : basketBasic.basketId]);
    (0, react_1.useEffect)(() => {
        window.addEventListener('beforeunload', () => ScrollUtil_1.ScrollUtil.scrollToTop());
        return () => window.removeEventListener('beforeunload', () => ScrollUtil_1.ScrollUtil.scrollToTop());
    }, []);
    // TODO fastcheckout: should be removed after the fast checkout redesign is done
    if (pathname.includes(`buy.html/${common_types_1.RoutePathname.FAST_CHECKOUT}`)) {
        history.replace((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.DELIVERY}`, isInAuthorMode));
    }
    if (!shouldRender)
        return null;
    return ((0, jsx_runtime_1.jsx)(CheckoutContext_1.CheckoutProvider, { config: props, children: (0, jsx_runtime_1.jsx)(CheckoutDeliveryContext_1.CheckoutDeliveryProvider, { children: (0, jsx_runtime_1.jsxs)(CheckoutPaymentContext_1.CheckoutPaymentProvider, { children: [(0, jsx_runtime_1.jsx)(react_router_1.Route, { exact: true, path: [`${root}buy.html`, `${rootWithLanguageSuffix}/buy.html`], render: () => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(LockedBasketNotificationMessage_1.LockedBasketNotificationMessage, {}), (0, jsx_runtime_1.jsx)(CheckoutLoginContainer_1.CheckoutLoginContainer, {})] })) }), (0, jsx_runtime_1.jsx)(react_router_1.Route, { exact: true, path: [
                            `${root}buy.html/${common_types_1.RoutePathname.DELIVERY}`,
                            `${rootWithLanguageSuffix}/buy.html/${common_types_1.RoutePathname.DELIVERY}`,
                        ], render: () => ((0, jsx_runtime_1.jsx)(CheckoutLayoutContainer_1.CheckoutLayoutContainer, { step: CheckoutStepContext_1.CheckoutStep.DELIVERY, children: (0, jsx_runtime_1.jsx)(CheckoutDeliveryContainer_1.CheckoutDeliveryContainer, {}) })) }), (0, jsx_runtime_1.jsx)(react_router_1.Route, { exact: true, path: [
                            `${root}buy.html/${common_types_1.RoutePathname.PAYMENT}`,
                            `${rootWithLanguageSuffix}/buy.html/${common_types_1.RoutePathname.PAYMENT}`,
                        ], render: () => ((0, jsx_runtime_1.jsx)(CheckoutLayoutContainer_1.CheckoutLayoutContainer, { step: CheckoutStepContext_1.CheckoutStep.PAYMENT, children: (0, jsx_runtime_1.jsx)(CheckoutPaymentContainer_1.CheckoutPaymentContainer, {}) })) })] }) }) }));
};
exports.default = CheckoutContainer;
