"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClosenessQualifier = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const asNumeral_1 = require("../../../vendors/asNumeral");
const MESSAGE_AMOUNT_TEMPLATE = '{{{AMOUNT}}}';
const ClosenessQualifier = ({ amountUntilFreeDelivery, totalPriceSell, showProgressBar, freeDeliveryMessage, }) => {
    const { currency } = (0, common_components_1.useAemConfigurationContextWrapper)();
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { dataTestId: "closeness_qualifier", status: "informative", icon: { source: andes_icons_1.LightbulbIcon }, progress: showProgressBar ? (totalPriceSell / (totalPriceSell + amountUntilFreeDelivery)) * 100 : undefined, text: freeDeliveryMessage.replace(MESSAGE_AMOUNT_TEMPLATE, `${currency}${asNumeral_1.AsNumeral.getInstance().formatAsCurrency(amountUntilFreeDelivery)}`) }));
};
exports.ClosenessQualifier = ClosenessQualifier;
