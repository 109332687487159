"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeDeliveryPhonePreview = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const CustomerUtil_1 = require("../../../../util/CustomerUtil");
const DeliveryPreselectedCardView_1 = require("../DeliveryPreselectedCardView");
const HomeDeliveryPhonePreview = ({ phoneNumber, onEdit }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    return ((0, jsx_runtime_1.jsx)(DeliveryPreselectedCardView_1.DeliveryPreselectedCardView, { dataTestId: "hd_phone_card", header: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", strong: true, children: t('checkout.contact.phone') }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: (0, andes_react_1.formatPhoneNumberIntl)(CustomerUtil_1.default.convertPhoneNumberPrefix(phoneNumber, '00', '+')) })] }), onEdit: onEdit }));
};
exports.HomeDeliveryPhonePreview = HomeDeliveryPhonePreview;
