"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliverySummary = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const common_queries_1 = require("@as-react/common-queries");
const andes_react_1 = require("@yonderland/andes-react");
const react_query_1 = require("@tanstack/react-query");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const react_router_1 = require("react-router");
const CheckoutPaymentContext_1 = require("../../../context/CheckoutPaymentContext");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
const URLParamUtil_1 = require("../../../util/URLParamUtil");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const DeliveryPromiseMessage_1 = require("../../product-detail/delivery-promise/DeliveryPromiseMessage");
const DeliveryAddressCard_1 = require("../delivery/DeliveryAddressCard");
const StoreAddressCard_1 = require("./StoreAddressCard");
const CheckoutStepContext_1 = require("../../../context/CheckoutStepContext");
const DeliverySummary = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { businessUnitId, root, isInAuthorMode } = aemConfiguration;
    const isBasketLocked = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketLockState)());
    const [state] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const history = (0, react_router_1.useHistory)();
    const { step } = (0, CheckoutStepContext_1.useCheckoutStepState)();
    const queryClient = (0, react_query_1.useQueryClient)();
    const { getStoreInformation } = (0, common_queries_1.storeQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: storeInformation } = (0, react_query_1.useQuery)(getStoreInformation({ businessUnitId, storeId: ((_b = (_a = state === null || state === void 0 ? void 0 : state.info) === null || _a === void 0 ? void 0 : _a.deliveryAddress) === null || _b === void 0 ? void 0 : _b.storeId) || 0 }, { enabled: Boolean((_d = (_c = state === null || state === void 0 ? void 0 : state.info) === null || _c === void 0 ? void 0 : _c.deliveryAddress) === null || _d === void 0 ? void 0 : _d.storeId) }));
    if (!state || !((_e = state === null || state === void 0 ? void 0 : state.info) === null || _e === void 0 ? void 0 : _e.requiresDelivery) || step === CheckoutStepContext_1.CheckoutStep.DELIVERY) {
        return null;
    }
    const _h = state.info, { requiresDelivery } = _h, paymentInfo = tslib_1.__rest(_h, ["requiresDelivery"]);
    const isPickUpPoint = paymentInfo.chosenDeliveryOption === common_types_1.DeliveryOptionsEnum.PICKUP_POINT;
    const isCollectHQ = paymentInfo.chosenDeliveryOption === common_types_1.DeliveryOptionsEnum.COLLECT_HQ;
    const deliveryPromise = paymentInfo.deliveryPromiseMessage
        ? {
            deliveryMessage: paymentInfo.deliveryPromiseMessage,
            hasDeliveryMessage: (_f = paymentInfo.hasDeliveryMessage) !== null && _f !== void 0 ? _f : true,
            name: paymentInfo.chosenDeliveryOption,
            cutoff: paymentInfo.deliveryPromiseCutoff,
        }
        : null;
    const handleEditClick = (e) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        yield queryClient.refetchQueries({
            queryKey: common_queries_1.checkoutQueryKeys.getDeliveryInfo(),
            stale: false,
        });
        history.push((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.DELIVERY}`, isInAuthorMode));
    });
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "1", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleS", children: t(`buy.payment.delivery.information.header.${paymentInfo
                            .chosenDeliveryOption.toLowerCase()
                            .replace(/_/g, '')}`) }), !isBasketLocked && ((0, jsx_runtime_1.jsx)(andes_react_1.Link, { theme: "neutral", variant: "primary", href: `${root}buy.html/${common_types_1.RoutePathname.DELIVERY}`, onClick: handleEditClick, dataTestId: "back_to_delivery", children: t(`buy.edit`) }))] }), !isCollectHQ && paymentInfo.deliveryAddress && !paymentInfo.deliveryAddress.storeNumber && ((0, jsx_runtime_1.jsx)(DeliveryAddressCard_1.DeliveryAddressCard, { address: paymentInfo.deliveryAddress, pickUpPoint: isPickUpPoint })), storeInformation && ((_g = paymentInfo === null || paymentInfo === void 0 ? void 0 : paymentInfo.deliveryAddress) === null || _g === void 0 ? void 0 : _g.storeNumber) && ((0, jsx_runtime_1.jsx)(StoreAddressCard_1.StoreAddressCard, { storeDetails: storeInformation })), deliveryPromise && ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: deliveryPromise.cutoff ? ((0, jsx_runtime_1.jsx)(DeliveryPromiseMessage_1.default, { deliveryPromise: deliveryPromise })) : ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "success", dataTestId: "delivery_promise", children: paymentInfo.deliveryPromiseMessage })) }))] }));
};
exports.DeliverySummary = DeliverySummary;
