"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplePayCTA = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const ApplePayButton_1 = require("../../../apple-pay-button/ApplePayButton");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const BasketSelector_1 = require("../../../../redux/basket/selectors/BasketSelector");
const AnalyticsActions_creators_1 = require("../../../../redux/analytics/actions/AnalyticsActions.creators");
const SessionSelector_1 = require("../../../../redux/session/selectors/SessionSelector");
const useIsInViewport_1 = require("../../../../hooks/useIsInViewport");
const ApplePayCTA = ({ deliveryStoreName }) => {
    const applePayButtonRef = (0, react_1.useRef)(null);
    const isInViewport = (0, useIsInViewport_1.useIsInViewport)(applePayButtonRef);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const [applePayAnalyticsSent, setApplePayAnalyticsSent] = (0, react_1.useState)(false);
    const [scrollPositionChanged, setScrollPositionChanged] = (0, react_1.useState)(false);
    const dispatch = (0, react_redux_1.useDispatch)();
    const { totalBasketDiscountAmount, totalPriceSellBeforeDiscount, totalPriceSell, priceTotalBasket, priceDelivery } = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPricingState)());
    const totalVoucherAmount = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalVoucherAmountState)());
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const userEmail = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetUserEmail)());
    const clickAndCollectStoreId = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketClickAndCollectStoreIdState)());
    const onApplePayButtonClick = () => dispatch(AnalyticsActions_creators_1.analyticsActions.applePayClick());
    (0, react_1.useEffect)(() => {
        const analyticsData = { componentInfo: { componentName: 'apple pay', componentType: 'payment button' } };
        if (isInViewport && !applePayAnalyticsSent) {
            if (!scrollPositionChanged) {
                dispatch(AnalyticsActions_creators_1.analyticsActions.applePayButtonInitialInViewport(analyticsData));
            }
            else {
                dispatch(AnalyticsActions_creators_1.analyticsActions.applePayButtonImpression(analyticsData));
            }
            setApplePayAnalyticsSent(true);
        }
    }, [isInViewport, applePayAnalyticsSent]);
    (0, cotopaxi_1.useScrollPosition)(() => setScrollPositionChanged(true), [scrollPositionChanged]);
    const hasAndOnlyDigitalProduct = products.every(p => p.productType === common_types_1.ProductTypes.E_VOUCHER);
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "2", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Divider, { text: t('product.check.popup.or'), textProps: { color: 'subdued', variant: 'bodyS' } }), (0, jsx_runtime_1.jsx)(ApplePayButton_1.ApplePayButton, { totalPrices: {
                    totalBasketDiscountAmount: totalBasketDiscountAmount && totalPriceSellBeforeDiscount ? totalBasketDiscountAmount.toString() : '0',
                    totalPriceSell: (totalPriceSellBeforeDiscount || totalPriceSell).toString(),
                    priceTotalBasket: priceTotalBasket.toString(),
                    priceDelivery: priceDelivery.toString(),
                }, totalVoucherAmount: totalVoucherAmount, onClick: onApplePayButtonClick, hasAndOnlyDigitalProduct: hasAndOnlyDigitalProduct, userEmail: userEmail, clickAndCollectStoreId: clickAndCollectStoreId, storeName: deliveryStoreName, innerRef: applePayButtonRef })] }));
};
exports.ApplePayCTA = ApplePayCTA;
