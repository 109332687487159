"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceOrderTrackingDetails = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const common_types_1 = require("@as-react/common-types");
const dayjs = require("dayjs");
/** Map of the Icons for the deliveries */
const locationIconMapping = {
    'shoe-repair': 'shoe-repair',
    'shoe-maintenance': 'maintenance',
    external: 'external',
    store_delivery_be: 'delivery',
    store_delivery_nl: 'delivery',
    store_delivery_uk: 'delivery',
    home_delivery_be: 'delivery',
    home_delivery_nl: 'delivery',
    home_delivery_uk: 'delivery',
    washing: 'washing',
    'internal-ski-maintenance': 'ski-maintenance',
    'clothing-repair': 'care-and-repair',
    'ready-for-shipment': 'delivery',
    'on-the-way-to-the-store': 'delivery',
    'on-the-way-to-the-customer': 'delivery',
    'ready-for-pickup': 'delivery',
    'paid-and-picked': 'delivery',
};
const ServiceOrderTrackingDetails = ({ timeline, serviceOrder, }) => {
    var _a, _b, _c;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const timeElements = timeline.map((elem) => {
        var _a, _b;
        return {
            title: `${t(elem.publicDisplayName)}${elem.location ? ' | ' + t(elem.location.publicDisplayName) : ''}`,
            subtitle: elem.location
                ? elem.location.status.map((status) => {
                    var _a;
                    return Object.assign({ text: `${t(status.publicDisplayName)} - ${dayjs(serviceOrder.createdAt).format(common_types_1.DateFormat.DD_MM_YYYY_HH_MM_DASH)}` }, (((_a = status === null || status === void 0 ? void 0 : status.updatedBy) === null || _a === void 0 ? void 0 : _a.name) && { tooltip: status.updatedBy.name }));
                })
                : elem.processedAt
                    ? [
                        Object.assign({ text: dayjs(serviceOrder.createdAt).format(common_types_1.DateFormat.DD_MM_YYYY_HH_MM_DASH) }, (((_a = elem === null || elem === void 0 ? void 0 : elem.updatedBy) === null || _a === void 0 ? void 0 : _a.name) && { tooltip: elem.updatedBy.name })),
                    ]
                    : [{ text: '-' }],
            iconKey: elem.location
                ? locationIconMapping[((_b = elem.location) === null || _b === void 0 ? void 0 : _b.key) || ''] || 'headquarter'
                : locationIconMapping[elem.key || ''] || 'headquarter',
            active: elem.current,
        };
    });
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingInline: "28", paddingBlock: "16", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingM", children: t('tracking.order.details.title') }), (0, jsx_runtime_1.jsxs)(andes_react_1.Box, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleM", children: t('tracking.order.details.number') }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { children: (0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: 2, children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: serviceOrder.label }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued-2", alignment: "end", children: dayjs(serviceOrder.createdAt).format(common_types_1.DateFormat.DD_MM_YYYY_EXTENDED) })] }) })] }), (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), (0, jsx_runtime_1.jsxs)(andes_react_1.Box, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleM", children: t('tracking.order.details.delivery') }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { children: (0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: 2, children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: t('tracking.order.details.delivery.info', {
                                            count: (_a = serviceOrder.delivery) === null || _a === void 0 ? void 0 : _a.leadTime,
                                            unit: t((_b = serviceOrder.delivery) === null || _b === void 0 ? void 0 : _b.publicDisplayName),
                                        }) }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued-2", alignment: "end", children: dayjs((_c = serviceOrder.delivery) === null || _c === void 0 ? void 0 : _c.date).format(common_types_1.DateFormat.DD_MM_YYYY_EXTENDED) })] }) })] }), (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), timeElements && ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleM", children: t('tracking.order.details.progress') }), (0, jsx_runtime_1.jsx)(andes_react_1.Timeline, { timeElements: timeElements, hasBackgroundColor: false })] }))] }) }));
};
exports.ServiceOrderTrackingDetails = ServiceOrderTrackingDetails;
