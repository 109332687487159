"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditCardSelectContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../../constants/i18nConstants");
const andes_icons_1 = require("@yonderland/andes-icons");
const NewCreditCardForm_1 = require("./NewCreditCardForm");
const CreditCardSelectContent = ({ selectedCard, cards, onSelectedCardChange, newCreditCardFormProps, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.general, i18nConstants_1.I18N_NAMESPACES.buy]);
    const [isAddNewCardSelected, setIsAddNewCardSelected] = (0, react_1.useState)(false);
    const handleCreditCardChange = (card, isAddNewCardSelected) => {
        setIsAddNewCardSelected(isAddNewCardSelected);
        onSelectedCardChange(card, isAddNewCardSelected);
    };
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)(andes_react_1.Box, { paddingBlockStart: { xs: '6', md: '3' }, paddingBlockEnd: { xs: isAddNewCardSelected ? '8' : '32', md: '8' }, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockEnd: "6", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingXS", children: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.creditcard.title.saved`) }) }), (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "3", itemsAlign: "start", children: [cards.map(card => {
                            const selected = !isAddNewCardSelected && card.id === (selectedCard === null || selectedCard === void 0 ? void 0 : selectedCard.id);
                            return ((0, jsx_runtime_1.jsxs)(andes_react_1.SelectionCard, { hasGapY: false, onClick: () => handleCreditCardChange(card, false), id: card.id, state: selected ? 'active' : 'resting', dataTestId: "saved_card_item", children: [(0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "media", cellProps: { align: 'stretch' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "0.5", children: (0, jsx_runtime_1.jsx)(andes_react_1.RadioButton, { id: card.id, name: "card-option", value: card.id, checked: selected, onChange: () => handleCreditCardChange(card, false) }) }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "header", cellProps: { align: 'center' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: card.cardNumber }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", children: (0, jsx_runtime_1.jsx)(andes_react_1.CollapsibleContent, { isOpen: selected, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: { xs: '3' }, children: (0, jsx_runtime_1.jsxs)(andes_react_1.Box, { padding: "4", backgroundColor: "quiet", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: selectedCard.cardName }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: selectedCard.brand })] }) }) }) })] }, card.id));
                        }), isAddNewCardSelected ? ((0, jsx_runtime_1.jsxs)(andes_react_1.SelectionCard, { hasGapY: false, onClick: () => handleCreditCardChange(null, true), id: "newCard", state: isAddNewCardSelected ? 'active' : 'resting', children: [(0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "media", cellProps: { align: 'stretch' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "0.5", children: (0, jsx_runtime_1.jsx)(andes_react_1.RadioButton, { id: "newCard", name: "card-option", value: "newCard", checked: isAddNewCardSelected, onChange: () => handleCreditCardChange(null, true) }) }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "header", cellProps: { align: 'center' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.creditcard.goto.new`) }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", children: (0, jsx_runtime_1.jsx)(andes_react_1.CollapsibleContent, { isOpen: isAddNewCardSelected, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: { xs: '3' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { padding: "4", backgroundColor: "quiet", children: (0, jsx_runtime_1.jsx)(NewCreditCardForm_1.NewCreditCardForm, Object.assign({}, newCreditCardFormProps, { showSubmit: true, fullWidth: true, onCancelClick: () => handleCreditCardChange(selectedCard, false) })) }) }) }) })] })) : ((0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { theme: "branded", dataTestId: "add-new-card-toggle", text: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.creditcard.goto.new`), onClick: () => handleCreditCardChange(null, true), iconLeft: { source: andes_icons_1.PlusIcon, size: 'sm' } }))] })] }) }));
};
exports.CreditCardSelectContent = CreditCardSelectContent;
