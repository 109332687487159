"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setInvoiceValues = exports.updateAddressCountryValues = exports.createDeliveryForm = exports.getInitialValues = exports.getPreselectedAddress = exports.initialValuesDeliveryForm = void 0;
const addressFields_utils_1 = require("../../form/address-fields/addressFields.utils");
const AddressUtil_1 = require("../../../util/AddressUtil");
const common_types_1 = require("@as-react/common-types");
const CustomerUtil_1 = require("../../../util/CustomerUtil");
exports.initialValuesDeliveryForm = {
    idTitle: undefined,
    firstName: '',
    middleName: '',
    lastName: '',
    saveAddress: false,
    address: addressFields_utils_1.emptyDeliveryAddressFields,
    showCustomTown: false,
    needInvoice: false,
    alternateInvoiceAddress: false,
    invoice: {
        vatCountry: '',
        vatNumber: '',
        companyName: '',
        companyDepartment: '',
        address: addressFields_utils_1.emptyAddressFields,
    },
};
const getPreselectedAddress = ({ deliveryAddress, deliveryAddresses, idCountry }, selectedAddress) => {
    if (selectedAddress) {
        return selectedAddress;
    }
    const defaultAddress = deliveryAddresses === null || deliveryAddresses === void 0 ? void 0 : deliveryAddresses.find(address => address.defaultAddress);
    const address = defaultAddress
        ? defaultAddress
        : (0, AddressUtil_1.hasPrefilledAddress)(deliveryAddress) && !(0, AddressUtil_1.isStoreDeliveryAddress)(deliveryAddress)
            ? deliveryAddress
            : deliveryAddresses === null || deliveryAddresses === void 0 ? void 0 : deliveryAddresses[0];
    return (address === null || address === void 0 ? void 0 : address.idCountry) === idCountry ? address : undefined;
};
exports.getPreselectedAddress = getPreselectedAddress;
const getInitialValues = (delivery, initValues, selectedAddress, optionName, idCountry) => {
    var _a;
    if (!delivery.customerDetails) {
        return initValues;
    }
    const { deliveryAddress, customerDetails } = delivery;
    const preselectedAddress = (0, exports.getPreselectedAddress)(delivery, selectedAddress);
    const address = preselectedAddress && preselectedAddress.idCountry === idCountry
        ? preselectedAddress
        : ((_a = customerDetails.address) === null || _a === void 0 ? void 0 : _a.idCountry) === idCountry
            ? customerDetails.address
            : addressFields_utils_1.emptyDeliveryAddressFields;
    let initialValues = Object.assign(Object.assign(Object.assign(Object.assign({}, initValues), { address: Object.assign(Object.assign({}, address), { addToProfile: false, idCountry: idCountry }) }), (0, exports.setInvoiceValues)(customerDetails, idCountry)), CustomerUtil_1.default.getCustomerNames(delivery, optionName));
    initialValues = (0, exports.updateAddressCountryValues)(initialValues, idCountry);
    const phoneNumber = deliveryAddress.phoneNumber || customerDetails.mobilePhoneNumber;
    if (phoneNumber) {
        initialValues = Object.assign(Object.assign({}, initialValues), { phoneNumber: CustomerUtil_1.default.convertPhoneNumberPrefix(phoneNumber, '00', '+') });
    }
    return initialValues;
};
exports.getInitialValues = getInitialValues;
const createDeliveryForm = (info, data) => {
    const delivery = Object.assign({}, info);
    if (!delivery.customerDetails) {
        delivery.customerDetails = {};
    }
    const deliveryForm = {
        data: delivery,
        updateCollectStoreData: (data, selectedStore) => {
            if (deliveryForm.data.customerDetails) {
                deliveryForm.data.customerDetails.needInvoice = false;
            }
            deliveryForm.data.deliveryDate = null;
            deliveryForm.data.deliveryAddress = Object.assign(Object.assign({}, deliveryForm.data.deliveryAddress), { addressLine2: data.address.addressLine2, addressLine3: data.address.addressLine3, addressLine4: data.address.addressLine4, addToProfile: false, idCountry: deliveryForm.data.idCountry, country: deliveryForm.data.country, customTown: data.address.customTown, companyName: data.address.companyName, address: data.address.address, houseNumber: data.address.houseNumber, houseNumberAddition: data.address.houseNumberAddition, postCode: data.address.postCode, city: data.address.city, county: data.address.county, state: data.address.state, firstName: data.firstName, middleName: data.middleName, lastName: data.lastName, idTitle: data.idTitle });
            if (selectedStore) {
                deliveryForm.data.store = selectedStore.storeId;
                deliveryForm.data.storeNumber = selectedStore.storeNumber;
            }
            return deliveryForm;
        },
        updatePhoneNumberData: (data) => {
            const phoneNumber = CustomerUtil_1.default.convertPhoneNumberPrefix(data.mobilePhoneNumber, '+', '00');
            if (deliveryForm.data.customerDetails) {
                deliveryForm.data.customerDetails.mobilePhoneNumber = phoneNumber;
            }
            deliveryForm.data.deliveryAddress.phoneNumber = phoneNumber;
            return deliveryForm;
        },
        updateHomeDeliveryData: (data, selectedAddress) => {
            deliveryForm.data.deliveryAddress = selectedAddress
                ? selectedAddress
                : Object.assign(Object.assign({}, data.address), { addressLine2: data.address.addressLine2, addressLine3: data.address.addressLine3, addressLine4: data.address.addressLine4, addToProfile: data.saveAddress, idCountry: deliveryForm.data.idCountry, country: deliveryForm.data.country, customTown: data.address.customTown, companyName: data.address.companyName, address: data.address.address, houseNumber: data.address.houseNumber, houseNumberAddition: data.address.houseNumberAddition, postCode: data.address.postCode, city: data.address.city, county: data.address.county, state: data.address.state, firstName: data.firstName, middleName: data.middleName, lastName: data.lastName, idTitle: data.idTitle });
            return deliveryForm;
        },
        updatePickupPointData: (data, isProviderPostNL) => {
            deliveryForm.updateCustomerData(data);
            if (isProviderPostNL && deliveryForm.data.customerDetails) {
                deliveryForm.data.customerDetails.address = Object.assign(Object.assign({}, deliveryForm.data.customerDetails.address), { postCode: data.address.postCode, houseNumber: data.address.houseNumber, houseNumberAddition: data.address.houseNumberAddition, address: data.address.address, city: data.address.city });
            }
            if (deliveryForm.data.pickupInformation) {
                deliveryForm.data.pickupInformation.properties.countryId = String(data.idCountry);
            }
            return deliveryForm;
        },
        updateInvoiceData: (data) => {
            var _a, _b;
            let invoiceAddress = {};
            if ((_b = (_a = deliveryForm.data.customerDetails) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.address) {
                invoiceAddress = Object.assign({}, deliveryForm.data.customerDetails.address);
            }
            if (data.needInvoice) {
                invoiceAddress = Object.assign(Object.assign(Object.assign({}, invoiceAddress), data.invoice.address), { idCountry: deliveryForm.data.idCountry });
            }
            if (!deliveryForm.data.customerDetails) {
                return deliveryForm;
            }
            deliveryForm.data.customerDetails.invoiceAddress = invoiceAddress;
            deliveryForm.data.customerDetails.needInvoice = data.needInvoice;
            deliveryForm.data.customerDetails.alternateInvoiceAddress = data.alternateInvoiceAddress;
            if (data.alternateInvoiceAddress) {
                deliveryForm.data.customerDetails.invoiceAddress = data.invoice.address;
            }
            deliveryForm.data.customerDetails.companyDepartment = data.invoice.companyDepartment;
            deliveryForm.data.customerDetails.companyName = data.invoice.companyName;
            deliveryForm.data.customerDetails.vatNumber = data.invoice.vatNumber;
            return deliveryForm;
        },
        updateCustomerData: (data) => {
            deliveryForm.data.deliveryAddress = Object.assign(Object.assign({}, deliveryForm.data.deliveryAddress), { firstName: data.firstName, middleName: data.middleName, lastName: data.lastName, idTitle: data.idTitle });
            return deliveryForm;
        },
    };
    if (CustomerUtil_1.default.isUpdateNamesAndTitleForCustomerDetails(delivery)) {
        deliveryForm.data.customerDetails = Object.assign(Object.assign({}, delivery.customerDetails), { firstName: data.firstName, middleName: data.middleName, lastName: data.lastName, idTitle: data.idTitle });
    }
    if (deliveryForm.data.pickupInformation) {
        deliveryForm.data.pickupInformation.properties.countryId = String(data.idCountry);
    }
    return deliveryForm;
};
exports.createDeliveryForm = createDeliveryForm;
const updateAddressCountryValues = (initialValues, idCountry) => {
    if (![common_types_1.CountryId.NL, common_types_1.CountryId.BE, common_types_1.CountryId.LU, common_types_1.CountryId.FR].includes(idCountry)) {
        initialValues.address.houseNumberAddition = '';
    }
    if (![common_types_1.CountryId.NL, common_types_1.CountryId.BE, common_types_1.CountryId.LU, common_types_1.CountryId.DE, common_types_1.CountryId.FR].includes(idCountry)) {
        initialValues.address.houseNumber = '';
    }
    if ([common_types_1.CountryId.NL, common_types_1.CountryId.BE, common_types_1.CountryId.LU, common_types_1.CountryId.DE, common_types_1.CountryId.FR].includes(idCountry)) {
        initialValues.address.addressLine2 = '';
    }
    if (![common_types_1.CountryId.GB, common_types_1.CountryId.GG, common_types_1.CountryId.JE].includes(idCountry)) {
        initialValues.address.addressLine3 = '';
    }
    return initialValues;
};
exports.updateAddressCountryValues = updateAddressCountryValues;
const setInvoiceValues = (customerDetails, idCountry) => {
    var _a, _b, _c, _d, _e;
    return ({
        needInvoice: customerDetails.needInvoice,
        invoice: Object.assign({}, (((_a = customerDetails === null || customerDetails === void 0 ? void 0 : customerDetails.invoiceAddress) === null || _a === void 0 ? void 0 : _a.idCountry) === idCountry
            ? {
                vatCountry: ((_b = customerDetails.vatNumber) === null || _b === void 0 ? void 0 : _b.slice(0, 2)) || '',
                vatNumber: (_c = customerDetails.vatNumber) !== null && _c !== void 0 ? _c : '',
                companyName: (_d = customerDetails.companyName) !== null && _d !== void 0 ? _d : '',
                companyDepartment: (_e = customerDetails.companyDepartment) !== null && _e !== void 0 ? _e : '',
                address: Object.assign(Object.assign({}, customerDetails.invoiceAddress), { idCountry: idCountry }),
            }
            : Object.assign(Object.assign({}, exports.initialValuesDeliveryForm.invoice), { address: Object.assign(Object.assign({}, exports.initialValuesDeliveryForm.invoice.address), { idCountry: idCountry }) }))),
    });
};
exports.setInvoiceValues = setInvoiceValues;
