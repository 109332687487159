"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterWizardModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const FilterUtil_1 = require("../../../../util/FilterUtil");
const FilterWizardItem_1 = require("./FilterWizardItem");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const react_redux_1 = require("react-redux");
const AnalyticsActions_creators_1 = require("../../../../redux/analytics/actions/AnalyticsActions.creators");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const useABTest_1 = require("../../../../hooks/useABTest");
const FilterWizardModal = ({ anchorEl, fetchSearchResultsCount, filter, filterWizardDescription, filterWizardImage, filterWizardTitle, filterWizardValues, handlePopoverClose, itemsCount, updateFilters, urlFilters, activeWizardFilterKeys, setActiveWizardFilterKeys, }) => {
    const [hasFilterUpdated, setFilterUpdated] = (0, react_1.useState)(false);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const dispatch = (0, react_redux_1.useDispatch)();
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const featureToggles = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const newMobileFilterDesignABCookie = (0, useABTest_1.useABTest)(common_types_1.ABTestCookie.NEW_MOBILE_FILTER_DESIGN);
    const showNewMobileFilterDesign = (0, FilterUtil_1.getABEnabledFeatureToggleValue)(featureToggles === null || featureToggles === void 0 ? void 0 : featureToggles.new_mobile_filter_design, newMobileFilterDesignABCookie);
    const getFilterQueryParams = (tmpSelectedFilterKeys, filterOn, filterType) => {
        var _a;
        const defaultFilter = urlFilters.defaultFilter ? (0, FilterUtil_1.getFilterQueryParameter)(urlFilters.defaultFilter) : '';
        const appliedFilters = ((_a = urlFilters.filter) === null || _a === void 0 ? void 0 : _a.length)
            ? urlFilters.filter.reduce((query, tmpFilter) => tmpFilter.filterOn === filterOn
                ? query
                : query + (0, FilterUtil_1.getFilterQueryParameter)(`${tmpFilter.filterOn}:${tmpFilter.filterValue}`), '')
            : '';
        const wizardFilters = tmpSelectedFilterKeys.length
            ? filterType === common_types_1.MULTISELECT_FILTER
                ? (0, FilterUtil_1.getMultiFilter)(filterOn, tmpSelectedFilterKeys)
                : (0, FilterUtil_1.getBucketFilter)(filterOn, tmpSelectedFilterKeys[0])
            : '';
        return `${defaultFilter}${appliedFilters}${wizardFilters}`.substring(1); // remove '&' from beginning of the string
    };
    const updateResultsCount = (query) => {
        fetchSearchResultsCount(query);
    };
    const onSelectChange = (filterKey) => {
        let newActiveWizardFilterKeys = [];
        if (filter.type === common_types_1.MULTISELECT_FILTER) {
            const selectedValueIndex = activeWizardFilterKeys.indexOf(filterKey);
            if (selectedValueIndex === -1) {
                newActiveWizardFilterKeys = [...activeWizardFilterKeys, filterKey];
            }
            else {
                newActiveWizardFilterKeys = [...activeWizardFilterKeys];
                newActiveWizardFilterKeys.splice(selectedValueIndex, 1);
            }
        }
        else {
            if (!activeWizardFilterKeys.includes(filterKey)) {
                newActiveWizardFilterKeys.push(filterKey);
            }
        }
        setActiveWizardFilterKeys(newActiveWizardFilterKeys);
        setFilterUpdated(true);
        updateResultsCount(getFilterQueryParams(newActiveWizardFilterKeys, filter.filterOn, filter.type));
    };
    const applyFilters = () => {
        const filterType = filter.type === common_types_1.BUCKET_FILTER ? common_types_1.BUCKET_FILTER : common_types_1.MULTISELECT_FILTER;
        updateFilters({
            filterOn: filter.filterOn,
            filterOnName: filter.name,
            filteredItems: filter.values.map((filterValue) => ({
                filterKey: filterValue.filterKey,
                filterKeyName: filterValue.name,
                filterOn: filter.filterOn,
                filterOnName: filter.name,
                filterType: filterType,
                isActive: activeWizardFilterKeys.includes(filterValue.filterKey),
            })),
            filterType: filterType,
            filterDisplayHint: filter.displayHint,
            filterContentType: filter.filterContentType,
            multiUpdate: true,
        });
        // Only call when user made changes to the filters.
        hasFilterUpdated && dispatch(AnalyticsActions_creators_1.analyticsActions.filterWizardSelected(filter.filterOn));
        handlePopoverClose();
    };
    (0, react_1.useEffect)(() => {
        window.addEventListener('popstate', handlePopoverClose);
        return () => window.removeEventListener('popstate', handlePopoverClose);
    }, []);
    if (!filter.values) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { anchorEl: anchorEl, type: showNewMobileFilterDesign && !isDesktop ? cotopaxi_1.PopoverType.FILTERS_WIZARD : cotopaxi_1.PopoverType.MODAL, onClose: handlePopoverClose, height: cotopaxi_1.PopoverHeight.LARGE, title: filterWizardTitle || filter.name, footerElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { paddingLeft: 2, paddingRight: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.RIGHT, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: isMobile, text: t('filterWizard.button.showResults', {
                        count: itemsCount,
                    }), onClick: applyFilters }) }) }), children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { variant: cotopaxi_1.ScrollableListVariant.SHADOW, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 2, marginRight: 2 } }, tablet: { spacing: { marginLeft: 4, marginRight: 4 } }, children: [(!!filterWizardDescription || !!filterWizardImage) && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1 } }, children: [!!filterWizardImage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 1 } }, tablet: { spacing: { paddingBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: filterWizardImage, lazy: true }) })), !!filterWizardDescription && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 1 } }, tablet: { spacing: { paddingBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, children: filterWizardDescription }) }))] })), filter.values.map((filterValue) => {
                        const filterWizardValue = filterWizardValues.find((filerWizardValue) => filerWizardValue.filterKey === filterValue.filterKey);
                        const isSelected = activeWizardFilterKeys.includes(filterValue.filterKey);
                        const _onSelectChange = () => onSelectChange(filterValue.filterKey);
                        return ((0, jsx_runtime_1.jsx)(FilterWizardItem_1.FilterWizardItem, { checked: isSelected, filterKey: filterValue.filterKey, title: (filterWizardValue === null || filterWizardValue === void 0 ? void 0 : filterWizardValue.title) || filterValue.name, itemCount: filterValue.itemCount, imageURL: filterWizardValue === null || filterWizardValue === void 0 ? void 0 : filterWizardValue.image, description: filterWizardValue === null || filterWizardValue === void 0 ? void 0 : filterWizardValue.description, onChange: _onSelectChange, multiSelect: filter.type === common_types_1.MULTISELECT_FILTER }, filterValue.filterKey));
                    })] }) }) }));
};
exports.FilterWizardModal = FilterWizardModal;
