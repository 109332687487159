"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WishlistButtonAndes = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const WishListActions_creators_1 = require("../../redux/wishList/actions/WishListActions.creators");
const WishListSelectors_1 = require("../../redux/wishList/selectors/WishListSelectors");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const WishlistButtonAndes = ({ productCode, productId, colourId, skuId, buttonProps, onToggle, dataTestId = 'add_to_wishlist', }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const shouldAnimate = (0, react_1.useRef)(false);
    const dispatch = (0, react_redux_1.useDispatch)();
    const inWishList = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeIsProductInWishList)(productCode, colourId));
    const mergedButtonProps = Object.assign(Object.assign({}, buttonProps), { icon: Object.assign({ source: inWishList ? andes_icons_1.HeartFillIcon : andes_icons_1.HeartIcon, animation: shouldAnimate.current ? 'pulse' : undefined }, ((buttonProps === null || buttonProps === void 0 ? void 0 : buttonProps.icon) || {})) });
    const handleWishListToggle = () => {
        shouldAnimate.current = !inWishList;
        dispatch(WishListActions_creators_1.wishListActions.toggleProduct({
            colorId: colourId,
            productCode,
            productId,
            sku: skuId,
        }));
        onToggle === null || onToggle === void 0 ? void 0 : onToggle();
    };
    if (isShoplanding) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(andes_react_1.IconButton, Object.assign({ dataTestId: dataTestId, size: "sm", theme: "neutral", variant: "plain", onClick: handleWishListToggle }, mergedButtonProps)));
};
exports.WishlistButtonAndes = WishlistButtonAndes;
