"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const ServiceOrderTrackingOverview_1 = require("../components/service-order-tracking/ServiceOrderTrackingOverview");
const ServiceOrderTrackingDetails_1 = require("../components/service-order-tracking/ServiceOrderTrackingDetails");
const useQueryStringParams_1 = require("../hooks/useQueryStringParams");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../constants/i18nConstants");
const OrderTrackingContainer = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServiceOrderEndpoint)(aemConfiguration);
    const hash = (0, useQueryStringParams_1.useQueryStringParams)('serviceOrder');
    const { getDetails, getTimeline } = (0, common_queries_1.trackingQueries)({
        servicesEndpoint,
    });
    const { data: details, error: detailsError } = (0, react_query_1.useQuery)(Object.assign(Object.assign({}, getDetails({ hash })), { enabled: !!hash }));
    const { data: timeline, error: timelineError } = (0, react_query_1.useQuery)(Object.assign(Object.assign({}, getTimeline({ hash })), { enabled: !!hash }));
    if (detailsError || timelineError) {
        return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "8", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingM", alignment: "center", children: t('tracking.order.not.found.title') }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyM", color: "brand", alignment: "center", children: t('tracking.order.not.found.message') })] }));
    }
    if (!details || !timeline) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: { base: 1, xl: 3 }, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { children: (0, jsx_runtime_1.jsx)(ServiceOrderTrackingOverview_1.ServiceOrderTrackingOverview, { serviceOrder: details }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: 2, children: (0, jsx_runtime_1.jsx)(ServiceOrderTrackingDetails_1.ServiceOrderTrackingDetails, { serviceOrder: details, timeline: timeline }) })] }));
};
exports.default = OrderTrackingContainer;
