"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TermsAndConditions = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const axios_1 = require("axios");
const react_query_1 = require("@tanstack/react-query");
const ComponentConfigContext_1 = require("../../../../context/ComponentConfigContext");
const TermsAndConditions = () => {
    const { totalMiniBlock } = (0, ComponentConfigContext_1.useBasketOverviewConfig)();
    const { data: terms } = (0, react_query_1.useQuery)({
        queryKey: ['termsAndConditions'],
        queryFn: () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: totalMiniBlock === null || totalMiniBlock === void 0 ? void 0 : totalMiniBlock.termsUrl,
                withCredentials: true,
            };
            const response = yield axios_1.default.request(axiosConfig);
            return response.data;
        }),
        enabled: Boolean(totalMiniBlock === null || totalMiniBlock === void 0 ? void 0 : totalMiniBlock.termsUrl),
    });
    if (!terms)
        return null;
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { backgroundColor: "quiet-3", padding: "4", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", color: "subdued", dataTestId: "terms_and_conditions", alignment: "center", children: (0, jsx_runtime_1.jsx)(andes_react_1.Prose, { html: terms }) }) }));
};
exports.TermsAndConditions = TermsAndConditions;
