"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAdditionalProductBasketAnalytics = exports.getBulkBasketAnalytics = exports.getBundleBasketAnalytics = exports.getBasketAnalytics = exports.isInactiveShoplandingBasket = exports.getBasicBasket = exports.updateBasketQuantity = exports.updateBasketLanguage = exports.setBasketData = exports.getBasket = exports.addBulkProductsToBasket = exports.addAdditionalProductsToBasket = exports.addProductBundleDealsToBasket = exports.addProductToBasket = exports.addExtensiveTileProductToBasket = exports.addProductGiftCardToBasket = exports.addProductPromotionToBasket = exports.addProductVoucherToBasket = exports.showAndHideBasketError = exports.closeBasketPopup = exports.openBasketPopupForBulkProducts = exports.openBasketPopupForAdditionalProducts = exports.openBasketPopupForExtensiveTileProduct = exports.openBasketPopupForVoucher = exports.openBasketPopupForBundle = exports.openBasketPopup = exports.showSizeForgottenTooltip = exports.showBasketPopup = exports.handleCustomEvent = exports.onBasketPopupClose = exports.basketPopupClose = exports.customEventSaga = exports.updateBasketQuantitySaga = exports.getBundleBasketAnalyticsSaga = exports.getBasketAnalyticsSaga = exports.showSizeForgottenTooltipSaga = exports.showBasketPopupSaga = exports.closeBasketPopupSaga = exports.addProductToBasketSaga = exports.getBasicBasketSaga = exports.getBasketSaga = exports.getProductData = exports.getSelectedSku = exports.getAddToBasketVoucherData = exports.getAddToBasketData = void 0;
const tslib_1 = require("tslib");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const effects_1 = require("redux-saga/effects");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const ProductDetailAPI_1 = require("../../../api/ProductDetailAPI");
const EventEnum_1 = require("../../../enums/EventEnum");
const BasketActions_creators_1 = require("../actions/BasketActions.creators");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const DateUtil_1 = require("../../../util/DateUtil");
const EventUtil_1 = require("../../../util/EventUtil");
const ProductUtil_1 = require("../../../util/ProductUtil");
const SagaContextSelectors_1 = require("../../SagaContextSelectors");
const RemoveProductFromBasketSaga_1 = require("./RemoveProductFromBasketSaga");
const RemoveProductFromBasketAnalyticsSaga_1 = require("./RemoveProductFromBasketAnalyticsSaga");
const RemoveProductAndUpdateSaga_1 = require("./RemoveProductAndUpdateSaga");
const ResetBasketSaga_1 = require("./ResetBasketSaga");
const CheckStockReservationSaga_1 = require("./CheckStockReservationSaga");
const UpdateProductQuantitySaga_1 = require("./UpdateProductQuantitySaga");
const UpdateBasketProductAnalyticsSaga_1 = require("./UpdateBasketProductAnalyticsSaga");
const IncreaseProductQuantitySaga_1 = require("./IncreaseProductQuantitySaga");
const DecreaseProductQuantitySaga_1 = require("./DecreaseProductQuantitySaga");
const ChangeSizeSaga_1 = require("./ChangeSizeSaga");
const BasketOverviewAnalyticsSaga_1 = require("./BasketOverviewAnalyticsSaga");
const MergeBasketsSaga_1 = require("./MergeBasketsSaga");
const BasketUtil_1 = require("../../../util/BasketUtil");
const AnalyticsActions_creators_1 = require("../../analytics/actions/AnalyticsActions.creators");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const MonetateUtil_1 = require("../../../util/MonetateUtil");
const BasketSelector_1 = require("../selectors/BasketSelector");
const getAddToBasketData = (state) => ({
    colorId: state.product.selectedColorId,
    sku: state.product.selectedSku,
    productCode: state.product.productCode,
    productId: state.product.productId,
});
exports.getAddToBasketData = getAddToBasketData;
const getAddToBasketVoucherData = (state) => {
    var _a;
    return ({
        productCode: state.product.productCode,
        productId: state.product.productId,
        colorId: state.product.defaultColor,
        productColorVariations: state.product.productColorVariations,
        title: state.product.productTranslatedProperties.title,
        brand: (_a = state.product.brand) === null || _a === void 0 ? void 0 : _a.description,
    });
};
exports.getAddToBasketVoucherData = getAddToBasketVoucherData;
const getSelectedSku = (state) => ({
    sku: state.product.selectedSku,
});
exports.getSelectedSku = getSelectedSku;
const getProductData = (state) => {
    var _a;
    const productColor = state.product.productColorVariations.find((productColorVariation) => productColorVariation.colorId === state.product.selectedColorId);
    const productSize = productColor.sizes.find((size) => size.sku === state.product.selectedSku);
    const sizeDescription = (0, ProductUtil_1.getSizeDescription)(productSize);
    return {
        sizeDescription,
        description: state.product.productTranslatedProperties.title,
        image: productColor.images[0].picture,
        productCode: state.product.productCode,
        colorDescription: productColor.translations.description,
        swatch: productColor.swatch,
        brand: (_a = state.product.brand) === null || _a === void 0 ? void 0 : _a.description,
        productHasDiscount: productColor.hasDiscount,
        productDiscountAmount: productSize.productDiscountAmount,
        productDiscountPercentage: productSize.productDiscountPercentage,
        prices: productSize.prices,
        productId: state.product.productId,
    };
};
exports.getProductData = getProductData;
const getCurrentProducts = (state) => state.basket.products;
const getCurrentMappedProducts = (state) => state.basket.mappedProducts;
const validateBasketData = (data, location, isCustomSizeSelector) => {
    if (data.sku === null || data.sku === undefined) {
        if (location === ActionEnums_1.ProductAddToBasketLocations.STICKY_BAR) {
            return common_types_1.BasketButtonError.SKU_STICKY_BAR;
        }
        else if (location === ActionEnums_1.ProductAddToBasketLocations.PDP && isCustomSizeSelector) {
            return common_types_1.BasketButtonError.SKU_OPEN_SIZE_SELECT;
        }
        else {
            return common_types_1.BasketButtonError.SKU;
        }
    }
    return null;
};
function* getBasketSaga() {
    yield (0, effects_1.throttle)(2000, ActionEnums_1.BasketActionTypes.GET, getBasket);
}
exports.getBasketSaga = getBasketSaga;
function* getBasicBasketSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.GET_BASIC, getBasicBasket);
}
exports.getBasicBasketSaga = getBasicBasketSaga;
function* addProductToBasketSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.ADD, addProductToBasket);
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.ADD_BUNDLE, addProductBundleDealsToBasket);
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.BASIC_ADD, addProductToBasket);
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.ADD_VOUCHER, addProductVoucherToBasket);
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.ADD_PROMOTION, addProductPromotionToBasket);
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.ADD_GIFT_CARD, addProductGiftCardToBasket);
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.ADD_EXTENSIVE_TILE_PRODUCT, addExtensiveTileProductToBasket);
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.ADD_BULK, addBulkProductsToBasket);
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.ADD_ADDITIONAL_PRODUCTS, addAdditionalProductsToBasket);
}
exports.addProductToBasketSaga = addProductToBasketSaga;
function* closeBasketPopupSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.POPUP_CLOSE, closeBasketPopup);
}
exports.closeBasketPopupSaga = closeBasketPopupSaga;
function* showBasketPopupSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.POPUP_SHOW, showBasketPopup);
}
exports.showBasketPopupSaga = showBasketPopupSaga;
function* showSizeForgottenTooltipSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.SIZE_FORGOTTEN_SHOW, showSizeForgottenTooltip);
}
exports.showSizeForgottenTooltipSaga = showSizeForgottenTooltipSaga;
function* getBasketAnalyticsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.ADD_SUCCESS, getBasketAnalytics);
}
exports.getBasketAnalyticsSaga = getBasketAnalyticsSaga;
function* getBundleBasketAnalyticsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.ADD_BUNDLE_SUCCESS, getBundleBasketAnalytics);
}
exports.getBundleBasketAnalyticsSaga = getBundleBasketAnalyticsSaga;
function* updateBasketQuantitySaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.UPDATE_QUANTITY, updateBasketQuantity);
}
exports.updateBasketQuantitySaga = updateBasketQuantitySaga;
function* customEventSaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.GeneralActionTypes.CUSTOM_EVENT, handleCustomEvent);
}
exports.customEventSaga = customEventSaga;
function* basketPopupClose() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.BasketActionTypes.POPUP_CLOSE, onBasketPopupClose);
}
exports.basketPopupClose = basketPopupClose;
function* onBasketPopupClose() {
    yield MonetateUtil_1.default.pushPageEvent();
}
exports.onBasketPopupClose = onBasketPopupClose;
function* handleCustomEvent(action) {
    switch (action.payload.eventType) {
        case EventEnum_1.EventEnum.BASKET_CHANGED:
        case EventEnum_1.EventEnum.LOGIN_SUCCESS:
            yield (0, effects_1.spawn)(getBasicBasket);
            break;
        default:
            break;
    }
}
exports.handleCustomEvent = handleCustomEvent;
function* showBasketPopup(action) {
    switch (action.payload) {
        case 'ERROR':
            yield (0, effects_1.fork)(openBasketPopup, common_types_1.BasketUpdateStatus.ERROR);
            break;
        case 'OUTOFSTOCK':
            yield (0, effects_1.fork)(openBasketPopup, common_types_1.BasketUpdateStatus.OUTOFSTOCK);
            break;
        default:
            yield (0, effects_1.fork)(openBasketPopup, common_types_1.BasketUpdateStatus.SUCCESS, 1);
    }
}
exports.showBasketPopup = showBasketPopup;
function* showSizeForgottenTooltip(action) {
    const sku = yield (0, effects_1.select)(exports.getSelectedSku);
    const basketDataErrorState = validateBasketData(sku, action === null || action === void 0 ? void 0 : action.payload);
    if (basketDataErrorState !== null) {
        yield (0, effects_1.fork)(showAndHideBasketError, basketDataErrorState);
    }
}
exports.showSizeForgottenTooltip = showSizeForgottenTooltip;
function* openBasketPopup(status, quantity, errorCode) {
    let basketProductData = yield (0, effects_1.select)(exports.getProductData);
    basketProductData = Object.assign(Object.assign(Object.assign({}, basketProductData), { productType: common_types_1.ProductTypes.PRODUCT }), (quantity && { quantity }));
    EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_POPUP_VISIBLE, true);
    yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createPopupOpenAction({
        basketProductData,
        status,
        errorCode,
    }));
}
exports.openBasketPopup = openBasketPopup;
function* openBasketPopupForBundle(status, basketProductData, price, errorCode) {
    EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_POPUP_VISIBLE, true);
    yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createPopupOpenAction({
        basketProductData,
        status,
        errorCode,
        price,
    }));
}
exports.openBasketPopupForBundle = openBasketPopupForBundle;
function* openBasketPopupForVoucher(status, basketProductData, errorCode) {
    EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_POPUP_VISIBLE, true);
    yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createPopupOpenAction({
        basketProductData,
        status,
        errorCode,
    }));
}
exports.openBasketPopupForVoucher = openBasketPopupForVoucher;
function* openBasketPopupForExtensiveTileProduct(status, addToBasketData, product, errorCode) {
    var _a, _b;
    const selectedColour = product.colours.find((colour) => colour.colourId === addToBasketData.colorId);
    const selectedSize = selectedColour.sizes.find((size) => size.sku === addToBasketData.sku);
    const sizeDescription = (0, ProductUtil_1.getSizeDescription)(selectedSize);
    const basketProductData = {
        description: product.title,
        colorDescription: selectedColour.description,
        sizeDescription,
        image: selectedColour.image,
        productCode: addToBasketData.productCode,
        quantity: addToBasketData.quantity,
        productId: addToBasketData.productId,
        brand: product.impression && product.impression.brand,
        colorId: selectedColour.colourId,
        prices: selectedSize.prices || selectedColour.prices,
        seoUrl: product.seoUrl,
        swatch: selectedColour.swatch,
        sku: Number(addToBasketData.sku),
        productHasDiscount: selectedColour.hasDiscount,
        productDiscountPercentage: (_a = selectedColour.price.productDiscountPercentage) !== null && _a !== void 0 ? _a : undefined,
        productDiscountAmount: (_b = selectedColour.price.productDiscountAmount) !== null && _b !== void 0 ? _b : undefined,
        productType: common_types_1.ProductTypes.PRODUCT,
    };
    EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_POPUP_VISIBLE, true);
    yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createPopupOpenAction({
        basketProductData,
        status,
        errorCode,
    }));
}
exports.openBasketPopupForExtensiveTileProduct = openBasketPopupForExtensiveTileProduct;
function* openBasketPopupForAdditionalProducts(activeProduct, products, basketProducts, status, errorCode) {
    const productsData = products.map(additionalProduct => {
        var _a, _b, _c, _d, _e;
        const { product, displayedColor } = additionalProduct;
        const selectedSize = displayedColor.sizes[0];
        if (!selectedSize) {
            return;
        }
        const sizeDescription = (0, ProductUtil_1.getSizeDescription)(selectedSize);
        return {
            description: product.title,
            colorDescription: displayedColor.description,
            sizeDescription,
            image: displayedColor.image,
            productCode: product.productCode,
            quantity: 1,
            productId: String(product.productId),
            brand: ((_a = product.impression) === null || _a === void 0 ? void 0 : _a.brand) || product.brand,
            colorId: displayedColor.colourId,
            prices: selectedSize.prices || displayedColor.prices,
            seoUrl: product.seoUrl,
            swatch: displayedColor.swatch,
            sku: Number(selectedSize.sku),
            productHasDiscount: displayedColor.hasDiscount,
            productDiscountPercentage: (_c = (_b = displayedColor.price) === null || _b === void 0 ? void 0 : _b.productDiscountPercentage) !== null && _c !== void 0 ? _c : undefined,
            productDiscountAmount: (_e = (_d = displayedColor.price) === null || _d === void 0 ? void 0 : _d.productDiscountAmount) !== null && _e !== void 0 ? _e : undefined,
            productType: common_types_1.ProductTypes.PRODUCT,
        };
    });
    const basketProductData = [activeProduct, ...productsData].filter(Boolean).map(product => {
        var _a;
        return Object.assign(Object.assign({}, product), { success: status !== common_types_1.BasketUpdateStatus.ERROR
                ? (_a = basketProducts.find(basketProduct => basketProduct.productId === Number(product.productId))) === null || _a === void 0 ? void 0 : _a.success
                : undefined });
    });
    EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_POPUP_VISIBLE, true);
    yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createPopupOpenAction({
        basketProductData,
        status,
        isBulk: true,
        errorCode,
    }));
}
exports.openBasketPopupForAdditionalProducts = openBasketPopupForAdditionalProducts;
function* openBasketPopupForBulkProducts(products, basketProducts, status, errorCode) {
    const basketProductData = products.map(({ product, colorId, sku, quantity }, index) => {
        var _a, _b, _c, _d;
        const selectedColour = product.colours.find(colour => colour.colourId === colorId);
        const selectedSize = selectedColour.sizes.find(size => size.sku === sku);
        const sizeDescription = (0, ProductUtil_1.getSizeDescription)(selectedSize);
        return {
            description: product.title,
            colorDescription: selectedColour.description,
            sizeDescription,
            image: selectedColour.image,
            productCode: product.productCode,
            quantity,
            productId: String(product.productId),
            brand: ((_a = product.impression) === null || _a === void 0 ? void 0 : _a.brand) || product.brand,
            colorId: selectedColour.colourId,
            prices: selectedSize.prices || selectedColour.prices,
            seoUrl: product.seoUrl,
            swatch: selectedColour.swatch,
            sku: Number(sku),
            productHasDiscount: selectedColour.hasDiscount,
            productDiscountPercentage: (_b = selectedColour.price.productDiscountPercentage) !== null && _b !== void 0 ? _b : undefined,
            productDiscountAmount: (_c = selectedColour.price.productDiscountAmount) !== null && _c !== void 0 ? _c : undefined,
            productType: common_types_1.ProductTypes.PRODUCT,
            success: status !== common_types_1.BasketUpdateStatus.ERROR ? (_d = basketProducts[index]) === null || _d === void 0 ? void 0 : _d.success : undefined,
        };
    });
    EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_POPUP_VISIBLE, true);
    yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createPopupOpenAction({
        basketProductData,
        status,
        isBulk: true,
        errorCode,
    }));
}
exports.openBasketPopupForBulkProducts = openBasketPopupForBulkProducts;
function* closeBasketPopup() {
    yield (0, effects_1.call)(EventUtil_1.default.dispatch, EventEnum_1.EventEnum.BASKET_POPUP_VISIBLE, false);
}
exports.closeBasketPopup = closeBasketPopup;
function* showAndHideBasketError(error) {
    yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createErrorAction(error));
    yield (0, effects_1.delay)(GeneralConstants_1.DELAY.DELAY_3000);
    yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createErrorAction(undefined));
}
exports.showAndHideBasketError = showAndHideBasketError;
function* addProductVoucherToBasket(action) {
    const addToBasketData = yield (0, effects_1.select)(exports.getAddToBasketVoucherData);
    const defaultSize = (0, ProductUtil_1.getDefaultSize)(addToBasketData.productColorVariations, addToBasketData.colorId, action.payload.voucherSizeCode);
    const requestParams = {
        sku: defaultSize.sku,
        productId: addToBasketData.productId,
        productCode: addToBasketData.productCode,
        colorId: addToBasketData.colorId,
        quantity: action.payload.quantity,
        productPriceManual: action.payload.amount,
        additionalProperties: {
            message: action.payload.message,
            layout: action.payload.imageTemplateName,
            image: action.payload.image,
        },
    };
    const basketProductData = {
        productType: common_types_1.ProductTypes.E_VOUCHER,
        description: addToBasketData.title,
        brand: addToBasketData.brand,
        quantity: action.payload.quantity,
        image: action.payload.image,
        productCode: addToBasketData.productCode,
        message: action.payload.message,
        productHasDiscount: false,
        price: action.payload.amount,
        productId: addToBasketData.productId,
    };
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        yield (0, effects_1.call)(BasketAPI_1.default.addProductToBasket, requestParams, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createAddSuccessAction({ product: requestParams }));
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_CHANGED);
        yield (0, effects_1.spawn)(openBasketPopupForVoucher, common_types_1.BasketUpdateStatus.SUCCESS, basketProductData);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
    }
    catch (e) {
        const { response } = e;
        const responseData = (response === null || response === void 0 ? void 0 : response.data) || null;
        yield (0, effects_1.spawn)(openBasketPopupForVoucher, common_types_1.BasketUpdateStatus.ERROR, basketProductData, responseData ? responseData.code : null);
    }
}
exports.addProductVoucherToBasket = addProductVoucherToBasket;
function* addProductPromotionToBasket(action) {
    const requestParams = action.payload;
    EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_PROMOTION_CALLED);
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        yield (0, effects_1.call)(BasketAPI_1.default.addProductToBasket, requestParams, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createAddSuccessAction({ product: requestParams }));
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_CHANGED);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
    }
    catch (_a) { }
}
exports.addProductPromotionToBasket = addProductPromotionToBasket;
function* addProductGiftCardToBasket(action) {
    const addToBasketData = yield (0, effects_1.select)(exports.getAddToBasketVoucherData);
    const defaultColorVariation = (0, common_utils_1.findColor)(addToBasketData.productColorVariations, addToBasketData.colorId);
    const defaultSize = (0, common_utils_1.findSize)(defaultColorVariation.sizes, action.payload.giftCardSizeCode);
    const requestParams = {
        sku: defaultSize.sku,
        productId: addToBasketData.productId,
        productCode: addToBasketData.productCode,
        colorId: addToBasketData.colorId,
        quantity: action.payload.quantity,
        productPriceManual: action.payload.amount,
    };
    const basketProductData = {
        productType: common_types_1.ProductTypes.GIFT_CARD,
        description: addToBasketData.title,
        quantity: action.payload.quantity,
        image: defaultColorVariation.images[0].picture,
        productCode: addToBasketData.productCode,
        productHasDiscount: false,
        price: action.payload.amount,
        productId: addToBasketData.productId,
    };
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        yield (0, effects_1.call)(BasketAPI_1.default.addProductToBasket, requestParams, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createAddSuccessAction({ product: requestParams }));
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_CHANGED);
        yield (0, effects_1.spawn)(openBasketPopupForVoucher, common_types_1.BasketUpdateStatus.SUCCESS, basketProductData);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
    }
    catch (e) {
        const { response } = e;
        const responseData = (response === null || response === void 0 ? void 0 : response.data) || null;
        yield (0, effects_1.spawn)(openBasketPopupForVoucher, common_types_1.BasketUpdateStatus.ERROR, basketProductData, responseData ? responseData.code : null);
    }
}
exports.addProductGiftCardToBasket = addProductGiftCardToBasket;
function* addExtensiveTileProductToBasket(action) {
    const { productData, location, analyticsData } = action.payload;
    const { product, colorId, sku, hideBasketPopup } = productData;
    const addToBasketData = {
        productCode: product.productCode,
        productId: product.productId.toString(),
        colorId,
        sku,
        quantity: 1,
    };
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const pageInfo = yield (0, effects_1.call)(SagaContextSelectors_1.getPageInfo, aemConfigurationContext);
        yield (0, effects_1.call)(BasketAPI_1.default.addProductToBasket, addToBasketData, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createAddSuccessAction({
            product: addToBasketData,
            location: location
                ? location
                : pageInfo.isBasketOverviewPage
                    ? ActionEnums_1.ProductAddToBasketLocations.BASKET_RECOMMENDATIONS
                    : undefined,
            analyticsData,
        }));
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetBasicAction());
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_CHANGED);
        if (!hideBasketPopup) {
            yield (0, effects_1.spawn)(openBasketPopupForExtensiveTileProduct, common_types_1.BasketUpdateStatus.SUCCESS, addToBasketData, product);
        }
    }
    catch (error) {
        const { response } = error;
        const responseData = (response === null || response === void 0 ? void 0 : response.data) || null;
        if (responseData && responseData.availableQuantity === 0) {
            yield (0, effects_1.spawn)(openBasketPopupForExtensiveTileProduct, common_types_1.BasketUpdateStatus.OUTOFSTOCK, addToBasketData, product);
        }
        else {
            yield (0, effects_1.spawn)(openBasketPopupForExtensiveTileProduct, common_types_1.BasketUpdateStatus.ERROR, addToBasketData, product, responseData ? responseData.code : undefined);
        }
    }
}
exports.addExtensiveTileProductToBasket = addExtensiveTileProductToBasket;
function* addProductToBasket(action) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const { storeSourceExcluded } = yield (0, effects_1.select)((0, ProductSelector_1.makeGetProductFulfillmentBySize)());
    let addToBasketData = yield (0, effects_1.select)(exports.getAddToBasketData);
    addToBasketData = Object.assign(Object.assign({}, addToBasketData), { quantity: action.payload.amount });
    const basketDataErrorState = validateBasketData(addToBasketData, action.payload.location, true);
    if (storeSourceExcluded && action.payload.location !== ActionEnums_1.ProductAddToBasketLocations.CLICK_AND_COLLECT) {
        yield (0, effects_1.spawn)(openBasketPopup, common_types_1.BasketUpdateStatus.ERROR, addToBasketData.quantity, common_types_1.BasketResponseCode.SS_EXCLUDED);
        return;
    }
    if (basketDataErrorState !== null) {
        yield (0, effects_1.fork)(showAndHideBasketError, basketDataErrorState);
    }
    else {
        try {
            const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
            const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
            yield (0, effects_1.call)(BasketAPI_1.default.addProductToBasket, addToBasketData, servicesEndpoint, defaultRequestParams);
            yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createAddSuccessAction({
                product: addToBasketData,
                pdpVersion: action.type === ActionEnums_1.BasketActionTypes.ADD ? action.payload.pdpVersion : undefined,
            }));
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_CHANGED);
            if (action.type === ActionEnums_1.BasketActionTypes.BASIC_ADD) {
                yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createStatusUpdateAction({
                    status: common_types_1.BasketUpdateStatus.SUCCESS,
                }));
                if (action.payload.updateBasket) {
                    yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
                }
            }
            else {
                yield (0, effects_1.spawn)(openBasketPopup, common_types_1.BasketUpdateStatus.SUCCESS, addToBasketData.quantity);
                yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
            }
        }
        catch (e) {
            const { response } = e;
            const responseData = (response === null || response === void 0 ? void 0 : response.data) || null;
            if (responseData && responseData.availableQuantity === 0 && action.type === ActionEnums_1.BasketActionTypes.BASIC_ADD) {
                yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createStatusUpdateAction({
                    status: common_types_1.BasketUpdateStatus.OUTOFSTOCK,
                }));
            }
            else if (responseData && responseData.availableQuantity === 0) {
                yield (0, effects_1.spawn)(openBasketPopup, common_types_1.BasketUpdateStatus.OUTOFSTOCK, addToBasketData.quantity);
            }
            else if (responseData && responseData.availableQuantity > 0 && action.type === ActionEnums_1.BasketActionTypes.ADD) {
                yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createAddSuccessAction({
                    product: Object.assign(Object.assign({}, addToBasketData), { quantity: responseData.availableQuantity }),
                    pdpVersion: action.payload.pdpVersion,
                }));
                EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_CHANGED);
                yield (0, effects_1.spawn)(openBasketPopup, common_types_1.BasketUpdateStatus.SUCCESS, responseData.availableQuantity);
                yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
            }
            else if (action.type === ActionEnums_1.BasketActionTypes.BASIC_ADD) {
                yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createStatusUpdateAction({
                    status: common_types_1.BasketUpdateStatus.ERROR,
                    errorCode: responseData ? responseData.code : undefined,
                }));
            }
            else {
                yield (0, effects_1.spawn)(openBasketPopup, common_types_1.BasketUpdateStatus.ERROR, addToBasketData.quantity, responseData ? responseData.code : undefined);
            }
        }
    }
}
exports.addProductToBasket = addProductToBasket;
function* addProductBundleDealsToBasket(action) {
    var _a;
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _b = yield (0, effects_1.select)((0, ProductSelector_1.makeGetBundleParentProduct)()), { bundle } = _b, parentData = tslib_1.__rest(_b, ["bundle"]);
    const parentSize = parentData.sizes.find(size => size.sku === parentData.selectedSku);
    const selectedSlots = bundle.bundleSlots.filter(slot => slot.isSelected);
    const addToBasketData = [
        {
            colorId: parentData.activeColor.colorId,
            productCode: bundle.productCode,
            productId: bundle.productId,
            quantity: 1,
            sku: parentData.selectedSku,
        },
        ...selectedSlots.map(slot => ({
            colorId: slot.displayedProduct.colourId,
            productCode: slot.displayedProduct.productCode,
            productId: slot.displayedProduct.productId,
            quantity: 1,
            sku: slot.displayedProduct.skuId,
        })),
    ];
    const basketProductData = [
        {
            brand: parentData.brand,
            description: parentData.title,
            image: bundle.image,
            productCode: parentData.productCode,
            sku: parentData.selectedSku,
            sizeDescription: (0, ProductUtil_1.getSizeDescription)(parentSize),
            originalSize: (0, ProductUtil_1.getSizeDescription)(parentSize, true),
            colorDescription: parentData.activeColor.translations.description,
            quantity: 1,
            productType: common_types_1.ProductTypes.PRODUCT,
        },
        ...selectedSlots.map(slot => {
            const selectedProduct = slot.bundleChildren.find(product => product.productCode === slot.displayedProduct.productCode);
            const selectedColor = selectedProduct.colours.find(color => color.colourId === slot.displayedProduct.colourId);
            const selectedSize = selectedColor.sizes.find(size => `${size.sku}` === `${slot.displayedProduct.skuId}`);
            return {
                brand: selectedProduct.brand,
                description: selectedProduct.title,
                image: selectedColor.image,
                productCode: selectedProduct.productCode,
                sizeDescription: (0, ProductUtil_1.getSizeDescription)(selectedSize),
                originalSize: (0, ProductUtil_1.getSizeDescription)(selectedSize, true),
                colorDescription: selectedColor.description,
                quantity: 1,
                productType: common_types_1.ProductTypes.PRODUCT,
                sku: parseInt(selectedSize.sku, 10),
            };
        }),
    ];
    const bundlePrice = (_a = bundle.calculations) === null || _a === void 0 ? void 0 : _a.data.bundleCalculationPrice;
    const basketPrice = {
        prices: bundlePrice.totalPrices,
        hasDiscount: bundlePrice.totalDiscountPercentage > 0,
        bundleDiscount: bundlePrice.bundleChildrenDiscountPct,
        productDiscountPercentage: bundlePrice.totalDiscountPercentage,
        productDiscountAmount: bundlePrice.totalDiscountAmount,
    };
    try {
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        yield (0, effects_1.call)(BasketAPI_1.default.addBundleToBasket, addToBasketData, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createAddBundleSuccessAction(addToBasketData, action.payload.location));
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_CHANGED);
        yield (0, effects_1.spawn)(openBasketPopupForBundle, common_types_1.BasketUpdateStatus.SUCCESS, basketProductData, basketPrice);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
    }
    catch (e) {
        const { response } = e;
        const responseData = response === null || response === void 0 ? void 0 : response.data;
        const unavailableProducts = Array.isArray(responseData)
            ? responseData.find(basketResponseItem => basketResponseItem.availableQuantity === 0)
            : [];
        if (responseData && responseData.availableQuantity === 0 && action.type === ActionEnums_1.BasketActionTypes.ADD_BUNDLE) {
            const updatedBasketProductData = basketProductData.map(data => {
                if (data.sku === responseData.skuId) {
                    return Object.assign(Object.assign({}, data), responseData);
                }
                return data;
            });
            yield (0, effects_1.spawn)(openBasketPopupForBundle, common_types_1.BasketUpdateStatus.BUNDLE_OUTOFSTOCK, updatedBasketProductData, basketPrice, responseData ? responseData.code : null);
        }
        else if (responseData && unavailableProducts.length > 0 && action.type === ActionEnums_1.BasketActionTypes.ADD_BUNDLE) {
            const updatedBasketProductData = basketProductData.map(data => {
                if (data.sku === responseData.skuId) {
                    return Object.assign(Object.assign({}, data), responseData);
                }
                return data;
            });
            yield (0, effects_1.spawn)(openBasketPopupForBundle, common_types_1.BasketUpdateStatus.BUNDLE_OUTOFSTOCK, updatedBasketProductData, basketPrice, responseData ? responseData.code : null);
        }
        else if (action.type === ActionEnums_1.BasketActionTypes.ADD_BUNDLE) {
            yield (0, effects_1.spawn)(openBasketPopupForBundle, common_types_1.BasketUpdateStatus.ERROR, basketProductData, basketPrice, responseData ? responseData.code : null);
        }
    }
}
exports.addProductBundleDealsToBasket = addProductBundleDealsToBasket;
function* addAdditionalProductsToBasket(action) {
    const { products, activeProductQuantity, location } = action.payload;
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    let addToBasketActiveProductData = yield (0, effects_1.select)(exports.getAddToBasketData);
    addToBasketActiveProductData = Object.assign(Object.assign({}, addToBasketActiveProductData), { quantity: activeProductQuantity });
    const basketDataErrorState = validateBasketData(addToBasketActiveProductData, location, true);
    if (basketDataErrorState !== null) {
        yield (0, effects_1.fork)(showAndHideBasketError, basketDataErrorState);
    }
    else {
        const activeProduct = yield (0, effects_1.select)(exports.getProductData);
        try {
            const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
            const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
            const addToBasketData = products.map(additionalProduct => {
                var _a;
                const { product, displayedColor } = additionalProduct;
                return {
                    productCode: product.productCode,
                    productId: String(product.productId),
                    colorId: displayedColor.colourId,
                    sku: (_a = displayedColor.sizes) === null || _a === void 0 ? void 0 : _a[0].sku,
                    quantity: 1,
                };
            });
            const addBulkProductsData = [addToBasketActiveProductData, ...addToBasketData];
            const basketProducts = yield (0, effects_1.call)(BasketAPI_1.default.addBulkProductsToBasket, addBulkProductsData, servicesEndpoint, defaultRequestParams);
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_CHANGED);
            const status = basketProducts.some(product => !product.success)
                ? common_types_1.BasketUpdateStatus.PARTIAL_SUCCESS
                : common_types_1.BasketUpdateStatus.SUCCESS;
            const addToBasketDataSuccess = addBulkProductsData.filter(product => {
                const currentBasketProduct = basketProducts.find(basketProduct => basketProduct.productId === Number(product.productId) && basketProduct.skuId === Number(product.sku));
                return currentBasketProduct.success;
            });
            const successfullAdditionalProducts = products.filter(product => !addToBasketDataSuccess.some(successProduct => successProduct.productId === product.product.productId));
            yield (0, effects_1.spawn)(getAdditionalProductBasketAnalytics, addToBasketDataSuccess, successfullAdditionalProducts, ActionEnums_1.ProductAddToBasketLocations.PDP_ADDITIONAL_PRODUCTS);
            yield (0, effects_1.spawn)(openBasketPopupForAdditionalProducts, activeProduct, products, basketProducts, status);
            yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
        }
        catch (e) {
            const { response } = e;
            const responseData = response === null || response === void 0 ? void 0 : response.data;
            if (Array.isArray(responseData) && responseData.some(item => item.availableQuantity === 0)) {
                yield (0, effects_1.spawn)(openBasketPopupForAdditionalProducts, activeProduct, products, responseData, common_types_1.BasketUpdateStatus.BULK_OUTOFSTOCK);
            }
            else {
                yield (0, effects_1.spawn)(openBasketPopupForAdditionalProducts, activeProduct, products, responseData || [], common_types_1.BasketUpdateStatus.ERROR, responseData === null || responseData === void 0 ? void 0 : responseData.code);
            }
        }
    }
}
exports.addAdditionalProductsToBasket = addAdditionalProductsToBasket;
function* addBulkProductsToBasket(action) {
    const { products, totalQuantityProducts, location } = action.payload;
    const addToBasketData = products.map(item => ({
        productCode: item.product.productCode,
        productId: String(item.product.productId),
        colorId: item.colorId,
        sku: item.sku,
        quantity: item.quantity,
    }));
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const basketProducts = yield (0, effects_1.call)(BasketAPI_1.default.addBulkProductsToBasket, addToBasketData, servicesEndpoint, defaultRequestParams);
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_CHANGED);
        const status = basketProducts.some(product => !product.success)
            ? common_types_1.BasketUpdateStatus.PARTIAL_SUCCESS
            : common_types_1.BasketUpdateStatus.SUCCESS;
        const addToBasketDataSuccess = addToBasketData.filter(product => {
            const currentBasketProduct = basketProducts.find(basketProduct => basketProduct.productId === Number(product.productId) && basketProduct.skuId === Number(product.sku));
            return currentBasketProduct.success;
        });
        const eventName = `${addToBasketDataSuccess.length}/${totalQuantityProducts}`;
        yield (0, effects_1.spawn)(getBulkBasketAnalytics, addToBasketDataSuccess, location, eventName);
        yield (0, effects_1.spawn)(openBasketPopupForBulkProducts, products, basketProducts, status);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
    }
    catch (error) {
        const { response } = error;
        const responseData = response === null || response === void 0 ? void 0 : response.data;
        if (Array.isArray(responseData) && responseData.some(item => item.availableQuantity === 0)) {
            yield (0, effects_1.spawn)(openBasketPopupForBulkProducts, products, responseData, common_types_1.BasketUpdateStatus.BULK_OUTOFSTOCK);
        }
        else {
            yield (0, effects_1.spawn)(openBasketPopupForBulkProducts, products, responseData || [], common_types_1.BasketUpdateStatus.ERROR, responseData === null || responseData === void 0 ? void 0 : responseData.code);
        }
    }
}
exports.addBulkProductsToBasket = addBulkProductsToBasket;
function* getBasket() {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const currency = yield (0, effects_1.call)(SagaContextSelectors_1.getCurrency, aemConfigurationContext);
        yield (0, effects_1.call)(CheckStockReservationSaga_1.checkStockReservation);
        const basketData = yield (0, effects_1.call)(BasketAPI_1.default.getBasket, servicesEndpoint, defaultRequestParams);
        if (!!basketData &&
            defaultRequestParams.shopId &&
            defaultRequestParams.shopId !== basketData.fictionalId.toString()) {
            yield (0, effects_1.call)(BasketAPI_1.default.shopAndFictionalIdCheck, servicesEndpoint, defaultRequestParams);
        }
        if (!basketData) {
            yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createSetAction({
                status: common_types_1.BasketStatus.EMPTY,
            }));
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_SET);
            MonetateUtil_1.default.pushBasketEvent([], currency);
        }
        else if (basketData.language && basketData.language !== aemConfigurationContext.lang) {
            yield (0, effects_1.fork)(updateBasketLanguage, aemConfigurationContext.lang, true);
        }
        else {
            yield (0, effects_1.call)(setBasketData, basketData);
        }
    }
    catch (_a) {
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createSetAction({
            status: common_types_1.BasketStatus.ERROR,
        }));
    }
}
exports.getBasket = getBasket;
function* setBasketData(basketData) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const currentProducts = yield (0, effects_1.select)(getCurrentProducts);
        const currentMappedProducts = yield (0, effects_1.select)(getCurrentMappedProducts);
        const currency = yield (0, effects_1.call)(SagaContextSelectors_1.getCurrency, aemConfigurationContext);
        const basketPopupState = yield (0, effects_1.select)((0, BasketSelector_1.makeGetBasketPopupState)());
        const addedProducts = basketData.products.filter(product => !currentProducts.find((currentProduct) => {
            var _a;
            return ((_a = currentProduct.additionalProperties) === null || _a === void 0 ? void 0 : _a.eVoucherId)
                ? currentProduct.additionalProperties.eVoucherId === product.additionalProperties.eVoucherId
                : currentProduct.sku === product.sku;
        }));
        const currentMappedProductsWithErrorState = currentMappedProducts.reduce((prevProduct, currentProduct) => currentProduct.errorState ? prevProduct.concat(currentProduct.productCode) : prevProduct, []);
        const newMappedProducts = yield (0, effects_1.all)(addedProducts.map((product) => (0, effects_1.call)(ProductDetailAPI_1.default.fetchProductForBasket, aemConfigurationContext.servicesApi, aemConfigurationContext.servicesApiPrefixAemServices, aemConfigurationContext.mainWebShop, aemConfigurationContext.fictiveWebShop, aemConfigurationContext.lang, product)));
        const tempMappedProducts = currentMappedProducts
            .filter((mappedProduct) => basketData.products.find((product) => mappedProduct.eVoucherId
            ? product.additionalProperties.eVoucherId === mappedProduct.additionalProperties.eVoucherId
            : product.sku === mappedProduct.sku))
            .concat(newMappedProducts);
        const mappedProducts = basketData.products.map((baseProduct) => {
            const mappedProduct = tempMappedProducts
                .map((product) => {
                var _a;
                const isProductHasErrorState = currentMappedProductsWithErrorState.includes(product.productCode);
                const actualProductErrorState = (_a = currentMappedProducts.find((currentProduct) => currentProduct.productCode === product.productCode)) === null || _a === void 0 ? void 0 : _a.errorState;
                return isProductHasErrorState
                    ? Object.assign(Object.assign({}, product), { errorState: actualProductErrorState }) : product;
            })
                .find((product) => baseProduct.additionalProperties.eVoucherId
                ? product.eVoucherId === baseProduct.additionalProperties.eVoucherId
                : baseProduct.sku === product.sku);
            return Object.assign(Object.assign({}, mappedProduct), { sseProduct: baseProduct.sseProduct, specialtyStoreProduct: baseProduct.specialtyStoreProduct, availableInSSEStore: baseProduct.availableInSSEStore, availableInSpecialtyStore: baseProduct.availableInSpecialtyStore, ccExcluded: baseProduct.ccExcluded, quantity: baseProduct.quantity, price: baseProduct.productPriceSell, previousPrice: baseProduct.productPriceStandard, totalPrice: baseProduct.totalProductPriceSell, totalPreviousPrice: baseProduct.totalProductPriceStandard, priceManual: baseProduct.productPriceManual, productDiscountAmount: baseProduct.productDiscountAmount, productDiscountPercentage: baseProduct.productDiscountPercentage, productHasDiscount: baseProduct.productDiscountPercentage > 0, additionalProperties: baseProduct.additionalProperties, prices: baseProduct.prices, totalProductDiscountAmount: baseProduct.totalProductDiscountAmount, promotion: baseProduct.promotion, features: baseProduct.features });
        });
        let mappedProductsWithAvailabilities = [];
        if (basketData.totalQuantity > 0) {
            const productAvailabilities = yield (0, effects_1.call)(ProductDetailAPI_1.default.getProductBasketAvailabilitiesByProductId, aemConfigurationContext.servicesApi, aemConfigurationContext.mainWebShop, mappedProducts.map(product => product.productId).join(','));
            mappedProductsWithAvailabilities = productAvailabilities
                ? BasketUtil_1.default.mergeProductAvailabilitiesToBasketData(mappedProducts, productAvailabilities)
                : mappedProducts;
        }
        const data = {
            deliveryInfo: {
                amountUntilFreeDelivery: basketData.amountUntilFreeDelivery,
                delivery: basketData.delivery,
                deliveryMessages: basketData.deliveryMessages,
                deliveryUnit: basketData.deliveryUnit,
                hasEvoucher: basketData.hasEvoucher,
                requiresDelivery: basketData.requiresDelivery,
                deliveryPromiseCutoff: basketData.deliveryPromiseCutoff,
                hasDeliveryMessage: basketData.hasDeliveryMessage,
            },
            products: basketData.products,
            mappedProducts: mappedProductsWithAvailabilities,
            promotionProducts: basketData.products.filter(product => product.promotion),
            pricing: {
                priceDelivery: basketData.priceDelivery,
                priceTotalBasket: basketData.priceTotalBasket,
                totalPriceSell: basketData.totalPriceSell,
                totalBasketDiscountAmount: basketData.totalBasketDiscountAmount,
                totalPriceSellBeforeDiscount: basketData.totalPriceSellBeforeDiscount,
                totalPendingPaymentAmount: basketData.totalPendingPaymentAmount,
                priceTotalBasketMinusVouchers: basketData.priceTotalBasketMinusVouchers,
            },
            status: basketData.totalQuantity > 0 ? common_types_1.BasketStatus.READY : common_types_1.BasketStatus.EMPTY,
            vouchers: basketData.vouchers,
            validateRegex: basketData.validateRegex,
            popupMessages: basketData.popupMessages,
            popupMessagesEnriched: basketData.popupMessagesEnriched,
            paybackVoucherMessages: basketData.paybackVoucherMessages,
            orderLinesHaveDifferentDeliveryPromises: basketData.orderLinesHaveDifferentDeliveryPromises,
            totalQuantity: basketData.totalQuantity,
            clickAndCollectStoreId: basketData.clickAndCollectStoreId,
            totalVoucherAmount: basketData.totalVoucherAmount,
            isBasketLocked: basketData.locked,
        };
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createSetAction(data));
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createAdobeAnalyticsTokenSetAction(basketData.basketId));
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.BASKET_SET);
        MonetateUtil_1.default.pushBasketEvent(data.mappedProducts, currency, basketPopupState.collapsed ? undefined : 'addtocart');
    }
    catch (_a) {
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createSetAction({
            status: common_types_1.BasketStatus.ERROR,
        }));
    }
}
exports.setBasketData = setBasketData;
function* updateBasketLanguage(language, shouldGetBasket) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    yield (0, effects_1.call)(BasketAPI_1.default.updateLanguage, language, servicesEndpoint, defaultRequestParams);
    if (shouldGetBasket) {
        yield (0, effects_1.fork)(getBasket);
    }
}
exports.updateBasketLanguage = updateBasketLanguage;
function* updateBasketQuantity(action) {
    const { sku } = yield (0, effects_1.select)(exports.getSelectedSku);
    if (!sku) {
        yield (0, effects_1.fork)(showAndHideBasketError, common_types_1.BasketButtonError.SKU);
    }
    else {
        try {
            const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
            const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
            const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
            const addToBasketData = yield (0, effects_1.select)(exports.getAddToBasketData);
            const requestParams = {
                sku: addToBasketData.sku,
                productId: addToBasketData.productId,
                productCode: addToBasketData.productCode,
                colorId: addToBasketData.colorId,
                quantity: Number(action.payload),
                productPriceManual: 0,
                additionalProperties: {},
            };
            const product = yield (0, effects_1.call)(BasketAPI_1.default.updateQuantity, requestParams, servicesEndpoint, defaultRequestParams);
            yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createUpdateQuantityResultAction(product));
        }
        catch (e) {
            const { response } = e;
            if (response.data.productId) {
                yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createUpdateQuantityResultAction(response.data));
            }
            yield (0, effects_1.fork)(showAndHideBasketError, common_types_1.BasketButtonError.STOCK);
        }
    }
}
exports.updateBasketQuantity = updateBasketQuantity;
function* getBasicBasket() {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
    const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
    const pageInfo = yield (0, effects_1.call)(SagaContextSelectors_1.getPageInfo, aemConfigurationContext);
    if (pageInfo.isConfirmationPage)
        return;
    try {
        let basicBasketData = yield (0, effects_1.call)(BasketAPI_1.default.getBasicBasket, servicesEndpoint, defaultRequestParams);
        if (aemConfigurationContext.isShopLanding) {
            const isInactive = yield (0, effects_1.call)(isInactiveShoplandingBasket, basicBasketData, aemConfigurationContext.shoplanding.timeout_reset_seconds);
            if (isInactive) {
                basicBasketData = yield (0, effects_1.call)(BasketAPI_1.default.resetBasketForShopLanding, servicesEndpoint, defaultRequestParams);
            }
        }
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createSetBasicAction(basicBasketData));
    }
    catch (_a) { }
}
exports.getBasicBasket = getBasicBasket;
function isInactiveShoplandingBasket(basketData, timeout_reset_seconds) {
    let timeDiff = null;
    if (basketData.dateUserInteraction && basketData.currentDate) {
        const timeDiffInMs = (0, DateUtil_1.calculateDifferenceInMs)(basketData.currentDate, basketData.dateUserInteraction);
        timeDiff = timeDiffInMs ? timeDiffInMs / 1000 : null;
    }
    if (timeDiff && timeDiff > timeout_reset_seconds) {
        return true;
    }
    return false;
}
exports.isInactiveShoplandingBasket = isInactiveShoplandingBasket;
function* getBasketAnalytics(action) {
    var _a, _b, _c;
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const analyticsGoogleTagManagerData = yield (0, effects_1.call)(BasketAPI_1.default.addProductToBasketAnalytics, action.payload.product, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGoogleAnalyticsSuccessAction(analyticsGoogleTagManagerData));
        const analyticsAdobeTagManagerData = yield (0, effects_1.call)(BasketAPI_1.default.addProductToBasketDtm, action.payload.product, servicesEndpoint, defaultRequestParams);
        if (action.payload.location && ((_a = analyticsAdobeTagManagerData.page) === null || _a === void 0 ? void 0 : _a.attributes)) {
            analyticsAdobeTagManagerData.page.attributes.addCardLocation = action.payload.location;
        }
        if (action.payload.pdpVersion === common_types_1.PDPVersion.V2) {
            yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.productDetailAddToBasket(analyticsAdobeTagManagerData));
        }
        else {
            if (action.payload.analyticsData && analyticsAdobeTagManagerData) {
                const { componentName, componentType, productTilePosition } = action.payload.analyticsData;
                analyticsAdobeTagManagerData.component = { componentInfo: { componentName, componentType } };
                if ((_c = (_b = analyticsAdobeTagManagerData.product) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.attributes) {
                    analyticsAdobeTagManagerData.product[0].attributes.productTilePosition = productTilePosition;
                }
            }
            yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createAdobeAnalyticsSuccessAction(analyticsAdobeTagManagerData));
        }
    }
    catch (_d) { }
}
exports.getBasketAnalytics = getBasketAnalytics;
function* getBundleBasketAnalytics(action) {
    var _a;
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const analyticsGoogleTagManagerData = yield (0, effects_1.call)(BasketAPI_1.default.addBundleToBasketAnalytics, action.payload.products, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGoogleAnalyticsSuccessAction(analyticsGoogleTagManagerData));
        const analyticsAdobeTagManagerData = yield (0, effects_1.call)(BasketAPI_1.default.addBundleToBasketDtm, action.payload.products, servicesEndpoint, defaultRequestParams);
        if (action.payload.location && ((_a = analyticsAdobeTagManagerData.page) === null || _a === void 0 ? void 0 : _a.attributes)) {
            analyticsAdobeTagManagerData.page.attributes.addCardLocation = action.payload.location;
        }
        yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.productDetailAddToBasket(analyticsAdobeTagManagerData));
    }
    catch (_b) { }
}
exports.getBundleBasketAnalytics = getBundleBasketAnalytics;
function* getBulkBasketAnalytics(products, location, eventName) {
    var _a, _b;
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const analyticsGoogleTagManagerData = yield (0, effects_1.call)(BasketAPI_1.default.addBundleToBasketAnalytics, products, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGoogleAnalyticsSuccessAction(analyticsGoogleTagManagerData));
        const analyticsAdobeTagManagerData = yield (0, effects_1.call)(BasketAPI_1.default.addBundleToBasketDtm, products, servicesEndpoint, defaultRequestParams);
        if (location && ((_a = analyticsAdobeTagManagerData.page) === null || _a === void 0 ? void 0 : _a.attributes)) {
            analyticsAdobeTagManagerData.page.attributes.addCardLocation = location;
        }
        if (analyticsAdobeTagManagerData.event && ((_b = analyticsAdobeTagManagerData.event) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            analyticsAdobeTagManagerData.event[0].eventInfo.eventName = eventName;
        }
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createAdobeAnalyticsSuccessAction(analyticsAdobeTagManagerData));
    }
    catch (_c) { }
}
exports.getBulkBasketAnalytics = getBulkBasketAnalytics;
function* getAdditionalProductBasketAnalytics(products, additionalProducts, location) {
    var _a;
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const servicesEndpoint = yield (0, effects_1.call)(SagaContextSelectors_1.getServicesEndpoint, aemConfigurationContext);
        const defaultRequestParams = yield (0, effects_1.call)(SagaContextSelectors_1.getDefaultRequestParams, aemConfigurationContext);
        const analyticsGoogleTagManagerData = yield (0, effects_1.call)(BasketAPI_1.default.addBundleToBasketAnalytics, products, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGoogleAnalyticsSuccessAction(analyticsGoogleTagManagerData));
        const analyticsAdobeTagManagerData = yield (0, effects_1.call)(BasketAPI_1.default.addBundleToBasketDtm, products, servicesEndpoint, defaultRequestParams);
        if (location && ((_a = analyticsAdobeTagManagerData.page) === null || _a === void 0 ? void 0 : _a.attributes)) {
            analyticsAdobeTagManagerData.page.attributes.addCardLocation = location;
        }
        yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.productDetailAddToBasket(analyticsAdobeTagManagerData, additionalProducts));
    }
    catch (_b) { }
}
exports.getAdditionalProductBasketAnalytics = getAdditionalProductBasketAnalytics;
function* basketSaga() {
    yield (0, effects_1.all)([
        addProductToBasketSaga(),
        closeBasketPopupSaga(),
        customEventSaga(),
        getBasketSaga(),
        getBasicBasketSaga(),
        getBasketAnalyticsSaga(),
        getBundleBasketAnalyticsSaga(),
        showBasketPopupSaga(),
        showSizeForgottenTooltipSaga(),
        updateBasketQuantitySaga(),
        (0, RemoveProductFromBasketSaga_1.removeProductFromBasketSaga)(),
        (0, RemoveProductFromBasketAnalyticsSaga_1.removeProductFromBasketAnalyticsSaga)(),
        (0, RemoveProductAndUpdateSaga_1.removeProductAndUpdateSaga)(),
        (0, ResetBasketSaga_1.resetBasketSaga)(),
        (0, CheckStockReservationSaga_1.checkStockReservationSaga)(),
        (0, UpdateProductQuantitySaga_1.updateProductQuantitySaga)(),
        (0, UpdateBasketProductAnalyticsSaga_1.updateBasketProductAnalyticsSaga)(),
        (0, IncreaseProductQuantitySaga_1.increaseProductQuantitySaga)(),
        (0, DecreaseProductQuantitySaga_1.decreaseProductQuantitySaga)(),
        (0, ChangeSizeSaga_1.changeSizeSaga)(),
        (0, BasketOverviewAnalyticsSaga_1.basketOverviewAnalyticsSaga)(),
        (0, MergeBasketsSaga_1.mergeBasketsSaga)(),
        basketPopupClose(),
    ]);
}
exports.default = basketSaga;
